import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Tempplate.scss"
import {
  Tabs,
  Card,
  CardContent,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginFileEncode,
  FilePondPluginImagePreview
);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Template() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [files, setFiles] = useState([]);
  const [age, setAge] = React.useState("");

  const handleChangeTree = (event) => {
    setAge(event.target.value);
  };
  return (
    <main>
      <Box className="title_bar">
        <Typography className="page_title">Template</Typography>
      </Box>
      <Box className="template_tab">
        <Card>
          <Tabs
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
          >
            <Tab label="Setup" {...a11yProps(0)} />
            <Tab label="Ideate" {...a11yProps(1)} />
            <Tab label="Quantify" {...a11yProps(2)} />
            <Tab label="Analyse" {...a11yProps(3)} />
            <IconButton>
              <span className="material-icons">add_circle_outlined</span>
            </IconButton>
          </Tabs>
          <CardContent>
            <TabPanel value={value} index={0} className="TabPanel">
              <Box className="tab_actions">
                <Typography variant="h5" className="tab_title">
                  Setup
                </Typography>
                <Box component="form" className="search_particular">
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search..."
                    inputProps={{ "aria-label": "search google maps" }}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <icon className="material-icons">search</icon>
                  </IconButton>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton>
                    <icon className="material-icons">mic</icon>
                  </IconButton>
                </Box>
                <Box className="right_actions">
                  <Button variant="outlined" size="medium">
                    <span className="material-icons">filter_alt_outlined</span>
                    {/* filter */}
                  </Button>
                  <Button variant="outlined" size="medium">
                    <span className="material-icons">chat_outlined</span>
                    {/* chat */}
                  </Button>
                </Box>
              </Box>
              <Box fullWidth>
                <FilePond
                  sx={{ mb: 2 }}
                  files={files}
                  onupdatefiles={setFiles}
                  allowMultiple={true}
                  maxFiles={3}
                  server="/api"
                  name="files"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                />
                <FormControl fullWidth className="textfield" sx={{ mb: 2 }}>
                  <InputLabel id="demo-simple-select-label">Age</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Age"
                    onChange={handleChangeTree}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  id="outlined-textarea"
                  label="Multiline Placeholder"
                  placeholder="Placeholder"
                  multiline
                  sx={{ mb: 2 }}
                  rows={4}
                />
              </Box>

              <Button variant="contained" color="secondary">
                Generate
              </Button>
            </TabPanel>
            <TabPanel value={value} index={1} className="TabPanel">
              Ideate
            </TabPanel>
            <TabPanel value={value} index={2} className="TabPanel">
              Quantify
            </TabPanel>
            <TabPanel value={value} index={3} className="TabPanel">
              Analyse
            </TabPanel>
          </CardContent>
        </Card>
      </Box>
    </main>
  );
}