import React from "react";
import Res from "../../assets/resources.png";

const Resources = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="resources-bg">
            <img src={Res} alt="resources image" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
