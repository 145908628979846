import React, { useEffect } from "react";
// import "../../ScheduleDuration/Setup/Setup.css";
import Header from "../../ScheduleDuration/Header/Header";
import ScheduleSidebar from "../../ScheduleDuration/ScheduleSidebar/ScheduleSidebar";
import SubHeader from "../SubHeader/SubHeader";
import { mlData } from "./mldata.jsx";
import { useState } from "react";
import axios from "axios";
import Tree2 from "./Tree2";
import _ from "lodash";
import Loader from "../../../components/UI/Loader";
import { removeChatbotScripts } from "../../../utils/utils";

function Setup2() {
  const [selectFile, setselectFile] = useState();
  const [querry, setQuerry] = useState("");
  const [qRes, setQRes] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const baseURL = "https://backend.sandbox-01.2bos.ai/api";

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src =
  //     "https://d2ri7jgo7n7mn7.cloudfront.net/lex-web-ui-loader.min.js";
  //   script.id = "lex-web-ui-loader-script";

  //   script.onload = function () {
  //     const scriptContent = `
  //       var loaderOpts = {
  //         baseUrl: 'https://d2ri7jgo7n7mn7.cloudfront.net/',
  //         shouldLoadMinDeps: true
  //       };
  //       var loader = new ChatBotUiLoader.IframeLoader(loaderOpts);
  //       var chatbotUiConfig = {
  //         lex: {
  //           sessionAttributes: {
  //             userAgent: navigator.userAgent,
  //             QNAClientFilter: ''
  //           }
  //         }
  //       };
  //       loader.load(chatbotUiConfig)
  //     .catch(function (error) { console.error(error); });`;

  //     const scriptTag = document.createElement("script");
  //     scriptTag.innerHTML = scriptContent;
  //     scriptTag.id = "lex-web-ui-loader-content-script"; // assign an id to the script tag
  //     document.head.appendChild(scriptTag);
  //   };

  //   document.head.appendChild(script);

  //   // Cleanup function to remove the script tags when the component is unmounted
  //   return () => {
  //     removeChatbotScripts();
  //   };
  // }, []);

  const uploadTreeHandle = async (event) => {
    const file = event.target.files[0];
    setselectFile(file);
    const formData = new FormData();

    formData.append("file", file);
    try {
      const config = {
        method: "POST",
        url: `${baseURL}/upload_document`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };
      const response = await axios(config);
    } catch (error) {}
  };

  const generateTreeHandle = async (event) => {
    if (selectFile && querry !== "") {
      const file = {
        file_name: selectFile.name,
        query: querry,
      };
      console.log(file);
      try {
        setIsLoading(true);
        const config = {
          method: "POST",
          url: `${baseURL}/generate_ai`,
          headers: {
            "Content-Type": "application/json",
          },
          data: file,
        };
        const response = await axios(config);
        console.log("mlData:");
        console.log(response);
        if (response && response !== undefined && response !== null)
          setQRes(response?.data?.data);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    } else {
      alert("File & Query Required");
    }
  };

  const uploadedFileDetails = () => {
    if (selectFile) {
      return (
        <div
          style={{
            margin: "25px auto auto",
            maxWidth: "18rem",
            borderRadius: "10px",
            background: "#fff",
            height: "45px",
            paddingTop: "10px",
            fontSize: "13px",
          }}
        >
          {selectFile.name}
        </div>
      );
    }
  };

  return (
    <>
      <div className="mb-3">
        <div className="help-text">
          <p>Studio</p>
        </div>
        <div className="btn-with-textarea">
          <div className="dropdown-tag">
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle btn-under-textarea"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ fontSize: "12px !important" }}
              >
                Opportunity
              </button>
              <ul
                className="dropdown-menu dropdown-menu-below"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <a className="dropdown-item" href="#">
                    Risk
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="textarea-ui pt-5 pb-1">
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="2"
              value={querry}
              onChange={(e) => setQuerry(e.target.value)}
              style={{ resize: "none", fontSize: "12px" }}
            ></textarea>
          </div>
        </div>
        {/* {uploadedFileDetails()} */}
        <div className="file-upload-box">
          <div className="mb-3">
            <input
              className="form-control"
              type="file"
              id="formFileMultiple"
              multiple
              onChange={uploadTreeHandle}
              style={{ fontSize: "13px" }}
            />
          </div>
        </div>
      </div>
      <div className="btngen-area">
        <button
          className="btn btn-success btn-generate"
          onClick={generateTreeHandle}
        >
          Generate
        </button>
      </div>
      <div className="tree" style={{ overflowX: "auto" }}>
        <ul className="ul-tree-padding" style={{ paddingLeft: "0 !important" }}>
          <li>
            <div className="tree-body">
              {isLoading && <Loader />}
              {!_.isEmpty(qRes) && (
                <ul style={{ paddingLeft: "0%" }}>
                  <Tree2 qRes={qRes} />
                </ul>
              )}
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Setup2;
