import { IconButton, styled } from "@mui/material";

export const AppHeaderStyle = styled("header")({
  display: "flex",
  padding: "24px 32px 8px",
});

export const HeaderCta = styled("div")({
  display: "block",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "flex-end",
  width: "100%",
});

export const MenuIcon = styled(IconButton)({
  width: "40px",
  height: "40px",
  padding: "5px",
  border: "1px solid #918FA8",
  borderRadius: 8,
  marginRight: 8,
  backgroundColor: "#fff",
});

export const HeaderMain = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
});
