import * as React from "react";
import { FormLabel, FormControl, Autocomplete, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const files = [
  "Program basis of schedule",
  "Contract document",
  "Risk register",
  "Schedule",
  "Procurement",
];

const FileType = () => {
  return (
    <FormControl sx={{ m: 1, width: "100%" }}>
      <FormLabel>File type (required)</FormLabel>
      <Autocomplete
        disablePortal
        id="add-file-type"
        className="add-file-type"
        options={files}
        slotProps={{
          paper: {
            sx: {
              "& .MuiAutocomplete-listbox": {
                "& .MuiAutocomplete-option": {
                  color: "#67667A",
                },
              },
            },
          },
        }}
        sx={{
          width: "100%",
          "#add-file-type": {
            color: "#67667A",
            padding: 0,
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <SearchIcon
                  style={{ verticalAlign: "middle", fill: "#67667A" }}
                />
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
};

export default FileType;
