import React from 'react'
import { NavLink } from "react-router-dom";
import "./InProgress.css";
import { FaRegCheckSquare } from "react-icons/fa";
import { FaRegBell } from "react-icons/fa";
import { RiWechatLine } from "react-icons/ri";
import SearchIcon from "../../assets/search-iconn.png";
// import { useState } from "react";

// function TableRows({ rows, tableRowRemove, onValUpdate }) {
//   return rows.map((rowsData, index) => {
//     const { name, email, profile } = rowsData;
//     return (
//       <tr key={index}>
//         <td>
//           <input
//             type="text"
//             value={name}
//             onChange={(event) => onValUpdate(index, event)}
//             name="name"
//             className="form-control"
//           />
//         </td>
//         <td>
//           <input
//             type="text"
//             value={email}
//             onChange={(event) => onValUpdate(index, event)}
//             name="email"
//             className="form-control"
//           />
//         </td>
//         <td>
//           <input
//             type="text"
//             value={profile}
//             onChange={(event) => onValUpdate(index, event)}
//             name="profile"
//             className="form-control"
//           />
//         </td>
//         <td>
//           <button
//             className="btn btn-dark"
//             onClick={() => tableRowRemove(index)}
//           >
//             Delete Row
//           </button>
//         </td>
//       </tr>
//     );
//   });
// }

function InProgress() {

  // const [rows, initRow] = useState([]);
  // const addRowTable = () => {
  //   const data = {
  //     name: "",
  //     email: "",
  //     profile: "",
  //   };
  //   initRow([...rows, data]);
  // };
  // const tableRowRemove = (index) => {
  //   const dataRow = [...rows];
  //   dataRow.splice(index, 1);
  //   initRow(dataRow);
  // };
  // const onValUpdate = (i, event) => {
  //   const { name, value } = event.target;
  //   const data = [...rows];
  //   data[i][name] = value;
  //   initRow(data);
  // };


  return (
    // <div>InProgress</div>
    <div className='inprogres-body pe-2 ps-5'>
      <div className='top-area d-flex pt-2 pb-2'>
        <div className='serach-box'>
          <div className="mt-1 inputs">
            <img src={SearchIcon} alt="" height={20} />
            <input type="text" className="form-control search-formcontrol " placeholder="Search here..." />
          </div>
        </div>
        <div className='checklist-area d-flex'>
          <div className='check-one'>
            <p><FaRegCheckSquare /></p>
          </div>
          <div className='notify-bell'>
            <p><FaRegBell /></p>
          </div>
          <div className='chatbot-icon'>
            {/* <p><RiWechatLine /></p> */}
          </div>
        </div>
      </div>
      <div className='inprogres-table'>
        <div className='page-heading pt-2 pb-3'>
          <h5>In-progress decisions</h5>
        </div>
        <div className='add-page-area d-flex w-100'>
          <p>All</p>
          <NavLink to="/durationreview"><button className='btn btn-primary btn-addnew'>Create New</button></NavLink>
        </div>
        <div className='table-area pt-2'>
          <table className="table table-tr">
            {/* <thead>
              <tr>
                <th scope="col">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                  </div>
                </th>
                <th scope="col">Lorem</th>
                <th scope="col">Ipsum</th>
                <th scope="col">Dolor</th>
              </tr>
            </thead> */}
            <tbody className='size-tdth'>
              <tr>
                <th scope="row" className='check-area'>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                  </div>
                </th>
                <td className='check-status'>
                  <div className='progres-status'>
                    <p>In-progress</p>
                  </div>
                </td>
                <td>
                  <div className='progres-active-count'>
                    <p>122</p>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row" className='check-area'>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                  </div>
                </th>
                <td>
                  <div className='progres-status'>
                    <p>In-progress</p>
                  </div>
                </td>
                <td></td>
              </tr>
              <tr>
                <th scope="row" className='check-area'>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                  </div>
                </th>
                <td>
                  <div className='progres-status'>
                    <p>In-progress</p>
                  </div>
                </td>
                <td></td>
              </tr>
              {/* <tr>
                <th scope="row">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                  </div>
                </th>
                <td>Adipisicing</td>
                <td>Elit</td>
                <td>Sint</td>
              </tr> */}
            </tbody>
          </table>
        </div>

        {/* <div className='Table-area'>
          <h2 className="text-center">React JS Add / Delete Table Rows Example</h2>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Profile</th>
                <th>
                  <button className="btn btn-danger" onClick={addRowTable}>
                    Insert Row
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <TableRows
                rows={rows}
                tableRowRemove={tableRowRemove}
                onValUpdate={onValUpdate}
              />
            </tbody>
          </table>
        </div> */}
      </div>
    </div>
  )
}

export default InProgress