import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { hasAndIsDefinedInSessionStorage } from "../../utils/utils";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !hasAndIsDefinedInSessionStorage("accessToken") &&
      !hasAndIsDefinedInSessionStorage("idToken")
    ) {
      navigate("/");
    }
  }, [navigate]);

  return <Component {...rest} />;
};

export default ProtectedRoute;
