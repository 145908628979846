// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/2BOS-underconstructionbg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.decision-bg{
    /* padding-top: 35px; */
    padding-top: 100px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Decisions/Decisions.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,yDAAkE;IAClE,iBAAiB;IACjB,4BAA4B;IAC5B,sBAAsB;IACtB,4BAA4B;IAC5B,2BAA2B;AAC/B","sourcesContent":[".decision-bg{\n    /* padding-top: 35px; */\n    padding-top: 100px;\n    background-image: url('../../assets/2BOS-underconstructionbg.png');\n    min-height: 100vh;\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-attachment: fixed;\n    background-position: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
