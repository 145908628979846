// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-width {
	width: 5.2% !important;
	background-color: #000;
	/* position: fixed;
	height: 100vh;  */
}

.durationreview_area {
	background-color: #f6f6f6;
	width: 94.8% !important;
	/* height: 100vh; */
}
`, "",{"version":3,"sources":["webpack://./src/pages/ScheduleDuration/DurationReview/DurationReview.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB,sBAAsB;CACtB;kBACiB;AAClB;;AAEA;CACC,yBAAyB;CACzB,uBAAuB;CACvB,mBAAmB;AACpB","sourcesContent":[".sidebar-width {\n\twidth: 5.2% !important;\n\tbackground-color: #000;\n\t/* position: fixed;\n\theight: 100vh;  */\n}\n\n.durationreview_area {\n\tbackground-color: #f6f6f6;\n\twidth: 94.8% !important;\n\t/* height: 100vh; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
