import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  enableNavDrawer: true,
};

export const navDrawerSlice = createSlice({
  name: "nav-drawer",
  initialState,
  reducers: {
    showDrawer: (state) => {
      state.enableNavDrawer = true;
    },
    hideDrawer: (state) => {
      state.enableNavDrawer = false;
    },
  },
});

export const { showDrawer, hideDrawer } = navDrawerSlice.actions;

export default navDrawerSlice.reducer;
