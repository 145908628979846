import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./Setup.scss";
import {
  Tabs,
  Card,
  CardContent,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Box,
  Typography,
  Tab,
  IconButton,
  InputBase,
  Divider,
  Stack,
  Alert,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
import SubHeader from "../SubHeader/SubHeader";
import { mlData } from "./mldata.jsx";
import { useState } from "react";
import axios from "axios";
import Tree from "./Tree";
// import Tree2 from "./Tree2";
import _ from "lodash";
import Loader from "../../../components/UI/Loader";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginFileEncode,
  FilePondPluginImagePreview
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function tabItem(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Setup() {
  const [tabID, setTabID] = useState(0);
  // const [files, setFiles] = useState([]);
  // const [age, setAge] = useState("");
  const [selectFile, setselectFile] = useState();
  const [querry, setQuerry] = useState("");
  const [qRes, setQRes] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const baseURL = "https://backend.sandbox-01.2bos.ai/api";
  const uploadTreeHandle = async (event) => {
    const file = event.target.files[0];
    setselectFile(file);
    const formData = new FormData();
    formData.append("file", file);
    try {
      const config = {
        method: "POST",
        url: `${baseURL}/upload_document`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };
      console.log("tree");
      const response = await axios(config);
    } catch (error) {
      console.log("error", error);
    }
  };

  const generateTreeHandle = async (event) => {
    if (selectFile && querry !== "") {
      const file = {
        file_name: selectFile.name,
        query: querry,
      };
      console.log(file);
      try {
        setIsLoading(true);
        const config = {
          method: "POST",
          url: `${baseURL}/generate_ai`,
          headers: {
            "Content-Type": "application/json",
          },
          data: file,
        };
        const response = await axios(config);
        console.log("mlData:");
        console.log(response);
        if (response && response !== undefined && response !== null)
          setQRes(response?.data?.data);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    } else {
      alert("File & Query Required");
    }
  };

  const uploadedFileDetails = () => {
    if (selectFile) {
      return (
        <div
          style={{
            margin: "25px auto auto",
            maxWidth: "13rem",
            borderRadius: "10px",
            background: "#fff",
            height: "45px",
            paddingTop: "10px",
            fontSize: "13px",
          }}
        >
          {selectFile.name}
        </div>
      );
    }
  };

  // const uploadedFileDetails = () => {
  //   if (selectFile) {
  //     return (
  //       <div
  //         style={{
  //           margin: "25px auto auto",
  //           maxWidth: "13rem",
  //           borderRadius: "10px",
  //           background: "#fff",
  //           height: "45px",
  //           paddingTop: "10px",
  //           fontSize: "13px",
  //         }}
  //       >
  //         {selectFile.name}
  //       </div>
  //     );
  //   }
  // };

  // const handleChangeTree = (event) => {
  //   setAge(event.target.value);
  // };

  const handleChange = (event, newID) => {
    setTabID(newID);
  };

  return (
    <Box className="template_tab">
      <Typography className="page_title">Decision</Typography>
      <Card>
        <TabContext value={tabID}>
          <TabList onChange={handleChange}>
            <Tab label="setup" {...tabItem(0)} />
            <Tab label="Ideate" {...tabItem(1)} />
            <Tab label="Quantity" {...tabItem(2)} />
            <Tab label="Analyse" {...tabItem(3)} />
            <IconButton>
              <span className="material-icons">add_circle_outlined</span>
            </IconButton>
          </TabList>
          <TabPanel value={tabID} index={0}>
            <Box className="tab_actions">
              {/* <Box component="form" className="search_particular">
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search..."
                  inputProps={{ "aria-label": "search google maps" }}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                <icon className="material-icons">search</icon>
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton>
                  <icon className="material-icons">mic</icon>
                </IconButton>
              </Box>
              <Stack className="right_actions" spacing={0} direction="row">
                <Button variant="outlined" size="medium" className="icon_btn">
                  <span className="material-icons">filter_alt_outlined</span>
                  filter
                </Button>
              </Stack> */}
            </Box>
            <Box fullWidth>
              <Box fullWidth className="mb-3">
                <input
                  className="form-control"
                  type="file"
                  id="formFileMultiple"
                  multiple
                  // value={selectFile}
                  onChange={uploadTreeHandle}
                  style={{ fontSize: "13px" }}
                />
              </Box>
              {/* <Box>{uploadedFileDetails()}</Box> */}
              {/* <FilePond
                sx={{ mb: 2 }}
                files={files}
                type="file"
                onupdatefiles={setFiles}
                onChange={uploadTreeHandle}
                allowMultiple={true}
                name="formFileMultiple"
                id="formFileMultiple"
                labelIdle='Drag & Drop your files or <span className="filepond--label-action">Browse</span>'
              /> */}
              {/* <FormControl fullWidth className="textfield" sx={{ mb: 2 }}>
                <InputLabel id="demo-simple-select-label">
                  Oppertunities
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age}
                  label="Oppertunities"
                  onChange={handleChangeTree}
                >
                  <MenuItem value={10}>Oppertunities</MenuItem>
                  <MenuItem value={20}>Oppertunities</MenuItem>
                  <MenuItem value={30}>Oppertunities</MenuItem>
                </Select>
              </FormControl> */}
              <TextField
                fullWidth
                id="outlined-textarea"
                label="Please Type Your Question Here"
                placeholder=""
                multiline
                sx={{ mb: 2 }}
                rows={4}
                value={querry}
                onChange={(e) => setQuerry(e.target.value)}
              />
            </Box>
            <Button
              variant="contained"
              color="secondary"
              onClick={generateTreeHandle}
            >
              Generate
            </Button>
            <Box>
              {isLoading && <Loader />}
              {!_.isEmpty(qRes) && (
                <ul style={{ paddingLeft: "0%" }}>
                  <Tree qRes={qRes} />
                </ul>
              )}
            </Box>
          </TabPanel>
          <TabPanel value={tabID} index={1}>
            <Typography>Ideate</Typography>
          </TabPanel>
        </TabContext>
      </Card>
    </Box>
  );
}
export default Setup;
