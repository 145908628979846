import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function Dashboard() {
  const [qlikApp, setQlikApp] = useState([]);
  const [jwt, setJWT] = useState("");

  const updateAppId = (id, name) => {
    localStorage.setItem("appId", id);
    localStorage.setItem("appName", name);
  };

  const config = {
    host: "2ln6gp19rtwk5ri.ap.qlikcloud.com",
    prefix: "/",
    port: 443,
    isSecure: true,
  };
  const setEmail = () => {
    if(localStorage.getItem("email") == 'dennis_momin@persistent.com' || localStorage.getItem("email") == 'yashavantha_kumar@persistent.com') {
      return localStorage.getItem("email")
    } else {
      return localStorage.getItem("email") || "mithun_das@persistent.com";
    }
  }

  useEffect(() => {
    (async () => {
      const rawResponse = await fetch(
        `https://backend.sandbox-01.2bos.ai/api/qlik-users`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          cache: "no-store",
        }
      );

      const content = await rawResponse.json();

      content.data &&
        content.data.map((itm, idx) => {
          if (localStorage.getItem("email") === itm.name) {
            console.log("Email: ", localStorage.getItem("email"));

            // fetch(
            //   "http://localhost:4000/jwt?sub=" + itm.id + "&email=" + itm.email
            // )
            // .then((res) => res.json())
            // .then((data) => {
            //     setJWT(data.jwt);

            //     async function jwtLogin() {
            //       return await fetch(
            //         "https://2ln6gp19rtwk5ri.ap.qlikcloud.com/login/jwt-session",
            //         {
            //           method: "POST",
            //           mode: "cors",
            //           credentials: "include",
            //           headers: {
            //             "Content-Type": "application/json",
            //             "qlik-web-integration-id":
            //               "3FgUaQb7Yay_uydz54VuaC48K-S75ucp",
            //             Authorization: "Bearer " + data.jwt,
            //           },
            //         }
            //       ).then((response) => {
            //         if (response.status !== 200) {
            //           throw new Error("failed to login via jwt");
            //         }
            //       });
            //     }

            //     jwtLogin();

            //     setTimeout(() => {
            //       fetch(
            //         "https://2ln6gp19rtwk5ri.ap.qlikcloud.com/api/v1/items?limit=100",
            //         {
            //           method: "GET",
            //           mode: "cors",
            //           credentials: "include",
            //           headers: {
            //             "Content-Type": "application/json",
            //             "qlik-web-integration-id":
            //               "3FgUaQb7Yay_uydz54VuaC48K-S75ucp",
            //           },
            //         }
            //       )
            //         .then((res) => res.json())
            //         .then((data) => {
            //           setQlikApp(data.data);
            //         });
            //     }, 1000);
            // })
            // .catch((err) => {
            //   console.log(err.message);
            // });
          }
        });
    })();
  }, []);

  return (
    <>
      <div className="pb-3 pt-2">
        <h1 className="inline-flex items-center mb-3 mt-3 pl-1 font-bold text-2xl">
          Project Controls Dashboard
        </h1>

        {jwt === "" ? (
          <Box
            sx={{ display: "flex", minHeight: "10vh", m: 8 }}
            justifyContent="center"
          >
            {/* <CircularProgress /> */}
          </Box>
        ) : (
          <div className="flex items-center space-x-4">
            <div className="grid grid-cols-3 gap-4">
              {qlikApp?.map((itm) => {
                return (
                  <div className="transition-all duration-300 hover:bg-gradient-to-br hover:from-cyan-500 hover:via-rose-500 hover:to-violet-700 rounded-xl overflow-hidden p-[1px] bg-gray-300 hover:p-[2px] m-[1px] hover:m-0 hover:shadow-xl group">
                    <Link
                      to={`/dashboard/app`}
                      // to={`./${itm.id}/${itm.name}`}
                      style={{
                        color: "#000",
                        fontWeight: 600,
                        textDecoration: "none",
                      }}
                      onClick={() => updateAppId(itm.resourceId, itm.name)}
                      id={itm.resourceId}
                    >
                      <div className="bg-white cursor-pointer rounded-[11px] hover:rounded-[10px] p-4 min-h-44 bg-[url('https://cdn.qlikcloud.com/qmfe/frontend-assets/1.39.0/artifacts/40px/app.svg')] bg-no-repeat bg-center">
                        <h3 className="group-hover:text-[#784EF5] text-base font-bold">
                          {itm.name}
                        </h3>
                        <p className="text-xs mb-0 text-gray-500"></p>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
