import Login from "./pages/Login/Login";
// import LoginNew from './pages/Login/LoginNew';
import Alice from "./pages/Alice/Alice";
import Decisions from "./pages/Decisions/Decisions";
import Hardhat from "./pages/Hardhat/Hardhat";
import Home from "./pages/Home/Home";
import InProgress from "./pages/Inprogress/InProgress";
import Issues from "./pages/Issues/Issues";
import Nodes from "./pages/Nodes/Nodes";
import Manufacton from "./pages/Manufacton/Manufacton";
import Opportunities from "./pages/Opportunities/Opportunities";
import Risk from "./pages/Risk/Risk";
import Analyse from "./pages/ScheduleDuration/Analyse/Analyse";
import DurationReview from "./pages/ScheduleDuration/DurationReview/DurationReview";
import Ideate from "./pages/ScheduleDuration/Ideate/Ideate";
import Quantify from "./pages/ScheduleDuration/Quantify/Quantify";
import Setup from "./pages/ScheduleDuration/Setup/Setup";
import Tempplate from "./pages/ScheduleDuration/Tempplate/Tempplate";
import Task from "./pages/Task/Tasks";
import CMBuilder from "./pages/CMBuilder/CMBuilder";

import { createBrowserRouter } from "react-router-dom";
// import Layout from "./components/UI/Layout";
import HardhatHelp from "./pages/Hardhat/HardhatHelp";
import HeadhatProjectTable from "./pages/Hardhat/HeadhatProjectTable";
import HeadhatTable from "./pages/Hardhat/HeadhatTable";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import HardhatProjectDetail from "./pages/Hardhat/HardhatProjectDetail";
import OtherUserHome from "./pages/Home/OtherUserHome";
import ServicePortalTemplates from "./pages/ServicePortalTemplates/ServicePortalTemplates";
//import Project_SRL from "./pages/Project-SRL/Project_SRL";
import Orchestration from "./pages/Orchestration/Orchestration";
import Logout from "./pages/Logout/Logout";
import Setup2 from "./pages/ScheduleDuration/Setup/Setup2";
import Intelligence from "./pages/Intelligence/Intelligence";
import Chat from "./pages/Chat/chat";
import Threads from "./pages/Threads/Threads";
import Resources from "./pages/Resources/Resources";
import Layout from "./container/Layout";
import Hub from "./pages/Hub";
import Boards from "./pages/Boards";
import Dashboard from "./pages/Dashboard";
import QlikAppId from "./components/Qlik/AppId";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/home",
    element: (
      <Layout>
        <Home />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/home2",
    element: (
      <Layout>
        <OtherUserHome />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/hub",
    element: (
      <Layout>
        <Hub />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/boards",
    element: (
      <Layout>
        <Boards />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/dashboard",
    element: (
      <Layout>
        <Dashboard />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/dashboard/app",
    element: (
      <Layout>
        <QlikAppId />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/intelligence",
    element: (
      <Layout>
        <Intelligence />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/orchestration",
    element: (
      <Layout>
        <Orchestration />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/templates",
    element: (
      <Layout>
        <ServicePortalTemplates />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/logout",
    element: (
      <Layout>
        <Logout />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/inprogress",
    element: (
      <Layout>
        <InProgress />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/decisions",
    element: (
      <Layout>
        <Decisions />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/risk",
    element: (
      <Layout>
        <Risk />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/opportunities",
    element: (
      <Layout>
        <Opportunities />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/issues",
    element: (
      <Layout>
        <Issues />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/task",
    element: (
      <Layout>
        <Task />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/hardhat",
    element: (
      <Layout>
        <Hardhat />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/alice",
    element: (
      <Layout>
        <Alice />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/nodes",
    element: (
      <Layout>
        <Nodes />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/manufacton",
    element: (
      <Layout>
        <Manufacton />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/cmbuilder",
    element: (
      <Layout>
        <CMBuilder />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/chat",
    element: (
      <Layout>
        <Chat />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/threads",
    element: (
      <Layout>
        <Threads />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/resources",
    element: (
      <Layout>
        <Resources />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  // Duration Review page with new sidebar and header routing starts here.....

  {
    path: "/durationreview",
    element: <DurationReview />,
    errorElement: <PageNotFound />,
  },

  {
    path: "/durationreview/tempplate",
    element: (
      <DurationReview>
        <Tempplate />
      </DurationReview>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/templates/setup2",
    element: (
      <Layout>
        <ServicePortalTemplates>
          <Setup2 />
        </ServicePortalTemplates>
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/templates/setup",
    element: (
      <Layout>
        <Setup />
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/templates/ideate",
    element: (
      <Layout>
        <ServicePortalTemplates>
          <Ideate />
        </ServicePortalTemplates>
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/templates/quantify",
    element: (
      <Layout>
        <ServicePortalTemplates>
          <Quantify />
        </ServicePortalTemplates>
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/templates/analyse",
    element: (
      <Layout>
        <ServicePortalTemplates>
          <Analyse />
        </ServicePortalTemplates>
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  // {
  //   path: "/project-srl",
  //   element: (
  //     <Layout>
  //       <Project_SRL />
  //     </Layout>
  //   ),
  //   errorElement: <PageNotFound />,
  // },
  // {
  //   path: "/durationreview/setup",
  //   element: <Setup />,
  //   errorElement: <PageNotFound />,
  // },
  // {
  //   path: "/durationreview/ideate",
  //   element: <Ideate />,
  //   errorElement: <PageNotFound />,
  // },
  // {
  //   path: "/durationreview/quantify",
  //   element: <Quantify />,
  //   errorElement: <PageNotFound />,
  // },
  // {
  //   path: "/durationreview/analyse",
  //   element: <Analyse />,
  //   errorElement: <PageNotFound />,
  // },
  {
    path: "/hardhat/mycomponent",
    element: (
      <Layout>
        <Hardhat>
          <HeadhatTable />
        </Hardhat>
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/hardhat/mycomponent/:userId",
    element: (
      <Layout>
        <Hardhat>
          <HardhatProjectDetail />
        </Hardhat>
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/hardhat/myprojects",
    element: (
      <Layout>
        <Hardhat>
          <HeadhatProjectTable />
        </Hardhat>
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/hardhat/myprojects/:userId",
    element: (
      <Layout>
        <Hardhat>
          <HardhatProjectDetail />
        </Hardhat>
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
  {
    path: "/hardhat/help",
    element: (
      <Layout>
        <Hardhat>
          <HardhatHelp />
        </Hardhat>
      </Layout>
    ),
    errorElement: <PageNotFound />,
  },
]);

// export const schedule_router=createBrowserRouter([
//     {
//         path: "/durationreview",
//         element: <DurationReview />,
//         // errorElement: <PageNotFound />,
//     },
//     {
//         path: "/tempplate",
//         element:<ScheduleDuration_Layout><Tempplate /></ScheduleDuration_Layout> ,
//         // errorElement: <PageNotFound />,
//     },
//     {
//         path: "/setup",
//         element: <Setup />,
//         // errorElement: <PageNotFound />,
//     },
//     {
//         path: "/ideate",
//         element: <Ideate />,
//         // errorElement: <PageNotFound />,
//     },
//     {
//         path: "/quantify",
//         element: <Quantify />,
//         // errorElement: <PageNotFound />,
//     },
//     {
//         path: "/analyse",
//         element: <Analyse />,
//         // errorElement: <PageNotFound />,
//     },
// ])

export default routes;
