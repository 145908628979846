import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Icons from "../../../components/Icons";
import { useState } from "react";

const menuItems = [
  {
    label: "Home",
    link: "/home",
    icon: "home",
  },
  // {
  //   label: "Reports",
  //   link: "/intelligence",
  //   icon: "summarize",
  //   muiIcon: true,
  // },
  {
    label: "Boards",
    link: "/boards",
    icon: "perspective",
  },
  {
    label: "Studio",
    link: "/templates/setup2",
    icon: "dataflow",
  },
  {
    label: "Registers",
    link: "/Orchestration",
    icon: "data",
  },
  {
    label: "Assets",
    link: "/hardhat/mycomponent",
    icon: "layoutAlt",
  },

  {
    label: "Hub",
    link: "/hub",
    icon: "folder",
  },
  {
    label: "Apps",
    icon: "cloud",
    child: [
      {
        label: "Construction Site Logistics",
        link: "https://login.cmcore.io/Account/Login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dcmbuilder-auth-code%26redirect_uri%3Dhttps%253A%252F%252Fapp.cmbuilder.io%252F_auth%252Flogin-callback%26response_type%3Dcode%26scope%3Dopenid%2520profile%2520offline_access%26state%3Dcb1502ef01b24c99a2d3b42ed8c4b094%26code_challenge%3DaTuu5BzApmlGxI6-KhKoFA8VQYotkjMOqQEVj40uH4c%26code_challenge_method%3DS256%26response_mode%3Dquery",
        icon: "hexagon",
        external: true,
      },
      {
        label: "Schedule Optimisation",
        link: "https://auth.nodeslinks.com/u/login?state=hKFo2SB3Y180QTFFOWVHb0tXelphOXViaUhfcVhrRS1QNjBTZKFur3VuaXZlcnNhbC1sb2dpbqN0aWTZIDlXZjB6aXpMZ3NNN2ZFQ0tqU2VKVjRkQk5FT29RUnpyo2NpZNkgUFh3dVF5dURZQXk0R3JoMlBpS1lhNHJ5alJJWGZqUW0",
        icon: "nodes",
        target: "_blank",
        external: true,
      },
      {
        label: "Schedule Optioneering",
        link: "https://alicetechnologieshelp.zendesk.com/auth/v2/login/signin?return_to=https%3A%2F%2Fsupport.alicetechnologies.com%2Fhc%2Fen-us%2Farticles%2F21994041529111-Using-Microsoft-Project-Schedules-in-ALICE-Core&theme=hc&locale=en-us&brand_id=360000091194&auth_origin=360000091194%2Ctrue%2Ctrue",
        icon: "alice",
        target: "_blank",
        external: true,
      },

      {
        label: "Project Controls Dashboard",
        link: "/dashboard",
        icon: "grid01",
      },
    ],
  },
  {
    label: "Query Documents",
    icon: "messageText",
    link: "/chat",
  },
];

const Links = ({ label, icon, isSelected, muiIcon }) => {
  return (
    <>
      <ListItemIcon sx={{ minWidth: "36px" }} className="scale-90">
        {muiIcon ? (
          <span
            className="material-icons-outlined"
            style={{ color: "#211F3D" }}
          >
            {icon}
          </span>
        ) : (
          <Icons name={icon} stroke={isSelected ? "#784EF5" : null} />
        )}
      </ListItemIcon>
      <ListItemText
        primary={label}
        sx={{
          color: isSelected ? "#784EF5" : "#211F3D",
          "& span": {
            fontWeight: isSelected ? "700" : "400",
            fontSize: 15,
            lineHeight: 1.25,
          },
        }}
        // className="leading-tight"
      />
    </>
  );
};

export default function MainMenu() {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleLink = (link, external) => {
    if (external) {
      window.open(link, "_blank");
      return;
    }
    navigate(link);
  };

  return (
    <List component="nav" disablePadding>
      {menuItems.map((item) => {
        const isSelected = location.pathname === item.link;
        if (item?.child && !!item?.child?.length) {
          return (
            <>
              <div onClick={() => handleLink(item.link || "#")} key={uuidv4()}>
                <ListItemButton
                  onClick={item.child ? handleClick : undefined}
                  sx={{
                    borderRadius: "6px",
                    padding: "6px 12px 6px 12px",
                  }}
                  selected={isSelected}
                  className="transition-all"
                >
                  <Links
                    label={item.label}
                    link={item.link}
                    icon={item.icon}
                    external={item.external}
                    isSelected={isSelected}
                    muiIcon={item.muiIcon}
                  />
                  {open ? (
                    <Icons name={"chevronUp"} />
                  ) : (
                    <Icons name={"chevronDown"} />
                  )}
                </ListItemButton>
              </div>
              <Collapse in={open} timeout="auto" unmountOnExit sx={{ pl: 2 }}>
                <List component="nav" disablePadding>
                  {item.child.map((childItem) => {
                    const isSelected = location.pathname === childItem.link;
                    return (
                      <div
                        onClick={() =>
                          handleLink(childItem.link || "#", childItem.external)
                        }
                        key={uuidv4()}
                        id={childItem.label.toLowerCase().replaceAll(" ", "-")}
                      >
                        <ListItemButton
                          sx={{
                            borderRadius: "6px",
                            padding: "6px 12px 6px 12px",
                          }}
                          selected={isSelected}
                          className="transition-all"
                        >
                          <Links
                            label={childItem.label}
                            link={childItem.link}
                            icon={childItem.icon}
                            external={childItem.external}
                            isSelected={isSelected}
                            muiIcon={childItem.muiIcon}
                          />
                        </ListItemButton>
                      </div>
                    );
                  })}
                </List>
              </Collapse>
            </>
          );
        }
        return (
          <div onClick={() => handleLink(item.link || "#")} key={uuidv4()}>
            <ListItemButton
              onClick={item.child ? handleClick : undefined}
              sx={{
                borderRadius: "6px",
                padding: "6px 12px 6px 12px",
                "&.Mui-selected": {
                  backgroundColor: "#F5F5FA",
                },
              }}
              selected={isSelected}
              className="transition-all"
            >
              <Links
                label={item.label}
                link={item.link}
                icon={item.icon}
                key={uuidv4()}
                external={item.external}
                isSelected={isSelected}
                muiIcon={item.muiIcon}
              />
            </ListItemButton>
          </div>
        );
      })}
    </List>
  );
}
