// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.durationreview_areaa{
    background-color: #f6f6f6;
	width: 100% !important;
	/* height: 100vh; */
}`, "",{"version":3,"sources":["webpack://./src/pages/ScheduleDuration/Analyse/Analyse.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;CAC5B,sBAAsB;CACtB,mBAAmB;AACpB","sourcesContent":[".durationreview_areaa{\n    background-color: #f6f6f6;\n\twidth: 100% !important;\n\t/* height: 100vh; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
