// import { PageDashboardPropsTypes } from "./types";

import { Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import QlikObject from "../../components/Qlik";

import {
  auth as qlikAuth,
  users as qlikUsers,
  spaces as qlikSpaces,
  qix as openAppSession,
} from "@qlik/api";
import { useEffect } from "react";

export default function Dashboard() {
  // const { id, name } = useParams();

  useEffect(() => {
    // const config = {
    //   authType: "oauth2",
    //   host: process.env.REACT_APP_QLIK_HOST,
    //   clientId: process.env.REACT_APP_QLIK_CLIENT_ID,
    //   clientSecret: process.env.REACT_APP_QLIK_CLIENT_SECRET,
    //   noCache: true,
    // };

    // qlikAuth.setDefaultHostConfig(config);
  }, []);

  return (
    <>
      <div className="mt-3 pb-3 pt-2">
        <h1 className="inline-flex items-center mb-3 pl-1 font-bold text-2xl">
          {localStorage.getItem("appName")
            ? localStorage.getItem("appName")
            : ""}
        </h1>
        <div
          style={{
            width: "100%",
            height: "900px",
            backgroundColor: "#fff",
            position: "relative",
            overflow: "auto",
          }}
        >
          <div>
            {localStorage.getItem("appId") ? (
              <QlikObject appId={localStorage.getItem("appId")} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}
