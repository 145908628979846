import React from "react";
import PlusIcon from "../../../assets/plusbold-icon.png";
import "../../ScheduleDuration/SubHeader/SubHeader.css";

function SubHeader() {
  return (
    <div>
      <div className="container">
        <div className="row text-center">
          <div className="col-md-12">
            <div className="subheader-tabmenu">
              <div className="subheader-wrapper">
                <div className="subheaderunder-menu submenu-first">
                  <p>Identify</p>
                </div>
                <div className="subheaderunder-menu submenu-second">
                  <p>Quantify</p>
                </div>
              </div>
              <div className="subheader">
                <div className="bar-icon right-notify-icon-plus">
                  <img src={PlusIcon} alt="" height={28} width={28} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubHeader;
