import React from "react";
// import Warehouse from "../../assets/bg-img33.jpg";
import Warehouse from "../../assets/wearhouse-bg1.jpg";
import Logo from "../../assets/logo.svg";
import SearchIcon from "../../assets/search-iconn.png";
import SearchIconn from "../../assets/search-icon-white.svg";
import BellIcon from "../../assets/bell-icon-white.png";
import UserIcon from "../../assets/user-icon.png";
import { NavLink } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import "../Hardhat/Hardhat.css";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import { useState } from "react";
import { GlobalFilter } from "./GlobalFilter";
import { useContext } from "react";
import { AppContext } from "../../Context/Appstateprovider";

function HardhatHeader({ globalFilter }) {
  // const { columns, setColumns, data, setData, globalFilter, setGlobalFilter } = useContext(AppContext);
  return (
    // <div>HardhatHeader</div>
    <div className="p-0 digital-component-top rounded-md overflow-hidden">
      <div className="row digital-component-header">
        <div className="col-md-2">
          <div className="logo">
            <img src={Logo} alt="" height={75} />
          </div>
        </div>
        <div className="col-md-10">
          <div className="navbar-section">
            <nav className="navbar navbar-expand-lg">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav navbar-spacing hardhat-nav">
                  <li className="nav-item active">
                    <NavLink
                      to="/hardhat/mycomponent"
                      activeClassName="is-active"
                    >
                      <div className="nav-link nav-menu">Home</div>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/hardhat/myprojects"
                      activeClassName="is-active"
                    >
                      <div className="nav-link nav-menu">My Projects</div>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/hardhat/help" activeClassName="is-active">
                      <div className="nav-link nav-menu">Support</div>
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="heading-icon d-flex">
                <div className="bell-iconn">
                  <img src={BellIcon} alt="" height={25} width={25} />
                </div>
                <div className="user-icon">
                  {/* <img src={UserIcon} alt="" height={30} /> */}
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="secondary"
                      id="dropdown-basic"
                      style={{
                        background: "none",
                        border: "none",
                        padding: "0",
                        marginLeft: "20px",
                      }}
                    >
                      <img
                        src={UserIcon}
                        alt=""
                        height={25}
                        width={25}
                        className="inline-block"
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="/hardhat/profile">
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item href="/hardhat/settings">
                        Settings
                      </Dropdown.Item>
                      <Dropdown.Item href="/hardhat/accountinfo">
                        Account Information
                      </Dropdown.Item>
                      <Dropdown.Item href="/hardhat/logout">
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="component-name">
                <h1>Digital Component Library</h1>
              </div>
              <div className="search-box-area search-main">
                {globalFilter}
                {/* <div className="input-group search-box-round">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search components..."
                  />
                  <div className="input-group-append">
                    <button className="btn btn-search" type="button">
                      <img src={SearchIconn} alt="" height={20} />
                    </button>
                  </div>
                </div> */}
                {/* <div className="mb-2" width="100%">
                  <GlobalFilter
                    filter={globalFilter}
                    setFilter={setGlobalFilter}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 p-0">
        <div className="header-background">
          <img src={Warehouse} alt="" />
        </div>
      </div>
    </div>
  );
}

export default HardhatHeader;
