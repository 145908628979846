import React, { useState, useRef } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import Loader from "../../components/UI/Loader";
import IFrame from "@uiw/react-iframe";
import "./chat.css";

import UploadFile from "../Hub/UploadFile2";

import { DropzoneArea } from "mui-file-dropzone";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UploadIcon from "../Hub/upload-cloud-02.svg";
import TrashIcon from "../Hub/trash-01.svg";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Chat = () => {
  const baseURL = "https://backend.sandbox-01.2bos.ai/api";
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fileDetails, setFileDetails] = useState(null);
  const [seed, setSeed] = useState(1);

  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState(null);

  const handleChange = (file) => {
    try {
      setFiles(file[0]);
      const f = file[0];

      console.log("file", file);

      const fileSizeInKB = f?.size / 1024;
      setFileDetails({
        fileName: f?.name,
        fileType: f?.type,
        fileSize: fileSizeInKB.toFixed(2) + "KB",
      });
    } catch (error) {
      toast.error(
        "The requested file could not be read, typically due to permission problems that have occurred after a reference to a file was acquired."
      );
    }
  };

  {
    /* const handleFileChange = (event) => {
    // const file = event.target.files[0];
    const file = event.target.files[0]

    console.log('event', event.target.files[0]);

    const fileSizeInKB = file.size / 1024;
    setFileDetails({
      fileName: file.name,
      fileType: file.type,
      fileSize: fileSizeInKB.toFixed(2) + "KB",
    });
  }; */
  }

  {
    /* const handleFileChange = (event) => {
    // const file = event.target.files[0];
    const file = event.target.files[0]

    console.log('event', event.target.files[0]);

    const fileSizeInKB = file.size / 1024;
    setFileDetails({
      fileName: file.name,
      fileType: file.type,
      fileSize: fileSizeInKB.toFixed(2) + "KB",
    });
  }; */
  }

  const handleClose = () => {
    setOpen(!open);
  };

  const handleSave = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", files);

    try {
      const config = {
        method: "POST",
        url: `${baseURL}/upload_document_kb`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };
      await axios(config);
      setOpen(false);
      setSeed(Math.random());
      toast.success("File uploaded successfully!");
    } catch (error) {
      console.log(error);
    }

    setFiles(null);
    // handleFileChange(e);
    setIsLoading(false);
  };

  {
    /* const handleUpload = async (e) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    console.log("first", e.target.files[0]);

    try {
      const config = {
        method: "POST",
        url: `${baseURL}/upload_document_kb`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };
      await axios(config);
    } catch (error) {
      console.log(error);
    }

    handleFileChange(e);
    setIsLoading(false);
  }; */
  }

  {
    /* const clearFileSelection = () => {
    setFileDetails(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }; */
  }

  {
    /* const handleSyncWithKb = async () => {
    setIsLoading(true);
    const response = await axios.get(`${baseURL}/update_to_kb`);
    if (response.status === 200) {
      const { message } = response?.data;
      clearFileSelection();
      setIsLoading(false);
      alert(message);
    }
  }; */
  }

  return (
    <div className="fdh">
      <div className="container">
        {/* <div className="row justify-content-center">
          <div className="col-md-6 mt-5">
            <div className="card">
              <div className="card-body">
                <form>
                  <Form.Group controlId="fileInput">
                    <Form.Control
                      type="file"
                      ref={fileInputRef}
                      onChange={handleUpload}
                    />
                  </Form.Group>
                  {isLoading && (
                    <div className="mt-3">
                      <Loader />
                    </div>
                  )}
                  {fileDetails && (
                    <div className="mt-3">
                      <h5>File Details</h5>
                      <ul>
                        <li>
                          <strong>File Name:</strong> {fileDetails.fileName}
                        </li>
                        <li>
                          <strong>File Type:</strong> {fileDetails.fileType}
                        </li>
                        <li>
                          <strong>File Size:</strong> {fileDetails.fileSize}
                        </li>
                      </ul>
                    </div>
                  )}
                </form>
              </div>
              <div className="card-footer float-right">
                <button
                  className="btn buttonColor float-end"
                  disabled={!fileDetails}
                  onClick={handleSyncWithKb}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div> */}

        <div className="upload-wrap">
          <FormControl
            sx={{
              m: 1,
              width: "100%",
            }}
          >
            <DropzoneArea
              dropzoneClass="upload-wrapper"
              dropzoneText={
                <Box
                  component="div"
                  className="upload-content"
                  px={6}
                  py={6}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius={4}
                  sx={{
                    paddingTop: "65px !important",
                    ".MuiBox-root": {
                      padding: "1px 7px",
                    },
                  }}
                >
                  <Box
                    mt={1}
                    sx={{
                      border: "1px solid #C4C3D9",
                      padding: "1px 6px",
                      borderRadius: "4px",
                    }}
                  >
                    <img src={UploadIcon} />
                  </Box>
                  <Box mt={1}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#918FA8",
                        fontSize: "0.88rem",
                      }}
                    >
                      <span style={{ color: "#784EF5" }}>Click to upload</span>{" "}
                      or drag and drop <br /> XER, CSV, JPG or PDF (20mb max)
                    </Typography>
                  </Box>
                </Box>
              }
              open={open}
              onSave={(e) => handleSave(e)}
              onClose={handleClose}
              acceptedFiles={["image/*", "application/pdf", "text/csv"]}
              maxFileSize={20971520}
              onChange={(e) => handleChange(e)}
              showPreviewsInDropzone={false}
              showPreviews={true}
              showFileNames={false}
              showFileNamesInPreview={false}
              filesLimit={1}
              getPreviewIcon={(file) => {
                return (
                  <Box
                    sx={{
                      backgroundColor: "#E7E6F2",
                      borderRadius: "4px",
                      padding: "7px !important",
                      textAlign: "left",
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={9}
                        sx={{
                          textAlign: "left",
                          fontWeight: "700",
                          fontSize: "0.9rem",
                          display: "inline-block",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {file.file.path.split(".")[0]}
                      </Grid>
                      <Grid item xs={2}>
                        <Box
                          component="span"
                          sx={{
                            padding: "1px 8px !important",
                            borderRadius: "3px !important",
                            fontSize: "12px !important",
                          }}
                          className={
                            "file-tag " + file.data.split(";")[0].split("/")[1]
                          }
                        >
                          {file.data.split(";")[0].split("/")[1].toUpperCase()}
                        </Box>
                      </Grid>
                      <Grid item xs={1}>
                        &nbsp;
                      </Grid>
                    </Grid>
                  </Box>
                );
              }}
              showAlerts={["error"]}
              sx={{
                borderStyle: "inset",
                borderWidth: "1px",
                border: "1px solid #918FA8",
                " .MuiBox-root": {
                  borderRadius: "4px",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 0,
                  paddingBottom: 0,
                },
              }}
              clearOnUnmount={true}
              key={seed}
            />
          </FormControl>

          <div className="flex justify-end">
            <div className="grid grid-cols-2">
              {
                <span
                  style={{
                    verticalAlign: "middle",
                    display: "inline-block",
                    marginTop: "5px",
                    marginLeft: "auto",
                    marginRight: "10px",
                  }}
                >
                  {isLoading ? (
                    <svg
                      aria-hidden="true"
                      className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  ) : (
                    ""
                  )}
                </span>
              }
              <button
                className="btn float-end h-10 w-32 submit-button"
                disabled={!fileDetails || isLoading}
                onClick={handleSave}
              >
                Submit
              </button>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <IFrame
            src={"https://d2ri7jgo7n7mn7.cloudfront.net"}
            style={{
              width: "100%",
              height: 420,
              marginTop: 50,
              marginBottom: 50,
              textAlign: "center",
            }}
          />
        </div>
      </div>

      <ToastContainer autoClose={500} />
    </div>
  );
};

export default Chat;
