import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import ComponentIconWearhouse from "../../assets/component-icon-wearhouse.png";
import Loader from "../../components/UI/Loader";
import "../Hardhat/Hardhat.css";
import { GlobalFilter } from "./GlobalFilter";
import MOCK_DATA from "./MOCK_PROJECT_DATA.json";

export default function HeadhatProjectTable({ value }) {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const processResponseData = (columns, data) => {
    const iD = {
      Header: "Id",
      accessor: "id",
      Cell: ({ row }) => row.index + 1,
    };
    const processedColumns = columns.map((column) => {
      // if (column.Header === "Project Name") {
      //   return {
      //     Header: "Project Name",
      //     accessor: "project_name",
      //     Cell: ({ row }) => (
      //       <Link
      //         className="id-name"
      //         to={`/hardhat/mycomponent/${row.original.project_name}`}
      //       >
      //         {row.original.project_name}
      //       </Link>
      //     ),
      //   };
      // } else {
        return column;
      // }
    });
    return [[iD, ...processedColumns], [...data]];
  };

  const wait = (delay) => {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  };

  useEffect(() => {
    setGlobalFilter(value);
  }, [value]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await wait(500);
      const [columns, rows] = processResponseData(
        MOCK_DATA.columns,
        MOCK_DATA.rows
      );
      setColumns(columns);
      setData(rows);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    setGlobalFilter,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageIndex, pageSize, globalFilter } = state;

  if (isLoading) return <Loader />;
  return (
    <>
      <div className="col-md-12">
        <div className="row pt-2 pb-2">
          <div className="col-md-4 d-flex pt-2">
            <div className="icon-area">
              <img src={ComponentIconWearhouse} alt="" height={25} />
            </div>
            <div className="component-heading">
              <h6 className="p-0">My Projects</h6>
            </div>
          </div>
          <div className="col-md-4 pt-2">
            {/* <div className="mb-2" width="100%">
              <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            </div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 component-table-area">
            <>
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {/* <div className="text-center mt-2">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {"<<"}
                </button>{" "}
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  Previous
                </button>{" "}
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  Next
                </button>{" "}
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>{" "}
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
                <span>
                  | Go to page:{" "}
                  <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const pageNumber = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(pageNumber);
                    }}
                    style={{ width: "50px" }}
                  />
                </span>{" "}
                <select
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                >
                  {[10, 20, 30].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div> */}
            </>
          </div>
        </div>
      </div>
    </>
  );
}
