import { Link } from "react-router-dom";
import { Drawer, Grid, IconButton } from "@mui/material";
import MainMenu from "../Menu";
import Icons from "../../../components/Icons";
import { useDispatch, useSelector } from "react-redux";
import { hideDrawer } from "../../../store/reducers/common/navDrawerSlice";
import WorkspaceMenu from "../WorkspaceMenu";
import logo from "../../../assets/img/2bos.svg";
import LogoutSection from "../LogoutSection";

// export default function Layout(props: PageLayoutPropsTypes){
export default function NavMain() {
  const open = useSelector((state) => state.navDrawer.enableNavDrawer);
  const dispatch = useDispatch();

  // const [open, setOpen] = useState(true);

  // const toggleDrawer = (newOpen: boolean) => () => {
  //   setOpen(newOpen);
  // };
  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const DrawerList = (
    <>
      <Grid
        container
        sx={{ p: 1, pt: 1.5 }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={10}>
          <Link to={`/`}>
            {/* <Icons name="logo" /> */}
            <img src={logo} alt="logo" width="90" />
          </Link>
        </Grid>
        <Grid
          item
          xs={2}
          onClick={() => dispatch(hideDrawer())}
          sx={{ textAlign: "center", cursor: "pointer" }}
        >
          <IconButton>
            <Icons name="menu" />
          </IconButton>
        </Grid>
      </Grid>

      <WorkspaceMenu />

      <MainMenu />

      <LogoutSection />
    </>
  );

  return (
    <Drawer
      sx={{
        width: "264px",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: "264px",
          boxSizing: "border-box",
          padding: "16px",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
      onClose={() => dispatch(hideDrawer())}
    >
      {DrawerList}
    </Drawer>
  );
}
