// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .home-bg{
    padding-top: 100px;
    background-image: url('../../assets/2BOS-underconstructionbg.png');
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
} */

.piechart-area {
  background: #ececec !important;
  margin: 10px;
  border-radius: 0px !important;
}
.barchat-heading h6 {
  text-align: center;
}
.home-heading-area {
  position: relative;
}
.heading-area-inside {
  position: absolute;
  bottom: 45px;
  width: 100%;
  text-align: center;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.css"],"names":[],"mappings":"AAAA;;;;;;;;GAQG;;AAEH;EACE,8BAA8B;EAC9B,YAAY;EACZ,6BAA6B;AAC/B;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,WAAW;AACb","sourcesContent":["/* .home-bg{\n    padding-top: 100px;\n    background-image: url('../../assets/2BOS-underconstructionbg.png');\n    min-height: 100vh;\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-attachment: fixed;\n    background-position: center;\n} */\n\n.piechart-area {\n  background: #ececec !important;\n  margin: 10px;\n  border-radius: 0px !important;\n}\n.barchat-heading h6 {\n  text-align: center;\n}\n.home-heading-area {\n  position: relative;\n}\n.heading-area-inside {\n  position: absolute;\n  bottom: 45px;\n  width: 100%;\n  text-align: center;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
