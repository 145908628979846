import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ComponentIconWearhouse from "../../assets/component-icon-wearhouse.png";
import UserIconCard from "../../assets/user-icon-card.png";
import Loader from "../../components/UI/Loader";
import "../Hardhat/HardhatProjectDetail.css";
import ComponentField from "./ComponentField";
import _ from "lodash";
import EditFormModal from "./EditFormModal";
import ApprovalModal from "./ApprovalModal";
import Accordion from "react-bootstrap/Accordion";
import RejectModal from "./RejectModal";
import {isoToLocalDateFormat} from "../../utils/utils";
import { useNavigate } from 'react-router-dom';

function HardhatProjectDetail() {
  // const baseURL = process.env.REACT_APP_API_URL;
  const baseURL = "https://backend.sandbox-01.2bos.ai/api";
  const navigate = useNavigate();
  const { user } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [formModalState, setFormModalState] = useState(false);
  //
  const [pyStatusWork, setPyStatusWork] = useState("");
  const [approvalData, setApprovalData] = useState([]);
  const [comment, setComment] = useState("");
  const [rejectcomment, setRejectComment] = useState("");
  const [compData, setCompData] = useState({});
  const [designersData, setdesignerData] = useState([]);
  const [projectsData, setprojectData] = useState([]);
  const [clientData, setSelectedClient] = useState([]);
  const [projectEngineer, setProjectEngineer] = useState([]);
  const [liftingPointData, setLiftingPoint] = useState([]);
  const [connectionTypesData, setConnectionTypes] = useState([]);
  const [engTestCertData, setEngTestCert] = useState([]);
  const [designStandards, setDesignStandards] = useState([]);
  const [installationDeliveryChecksData, setInstallationDeliveryChecks] =
    useState([]);

  //
  const params = useParams();
  const CASE_ID = params.userId.split(" ")[1];

  const [showApprovalModal, setApprovalModal] = useState(false);
  const approvalModalClose = () => setApprovalModal(false);
  const approvalModalShow = () => setApprovalModal(true);

  const [showRejectModal, setRejectModal] = useState(false);
  const rejectModalClose = () => setRejectModal(false);
  const rejectModalShow = () => setRejectModal(true);


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [
          overviewRes,
          specRes,
          designInfoRes,
          tradePartnerRes,
          projectRes,
          designRes,
          clientRes,
          engineerRes,
          liftingPointRes,
          connectionTypesRes,
          engtestcertRes,
          installationDeliveryChecksRes,
          designStandardsRes,
          approvalHistoryRes,
        ] = await Promise.all([
          axios.get(
            `${baseURL}/get_case_view_details?caseID=${params.userId}&viewID=Overview`
          ),
          axios.get(
            `${baseURL}/get_case_view_details?caseID=${params.userId}&viewID=Specification`
          ),
          axios.get(
            `${baseURL}/get_case_view_details?caseID=${params.userId}&viewID=DesignInformation`
          ),
          axios.get(
            `${baseURL}/get_case_view_details?caseID=${params.userId}&viewID=TradePartnerInformation`
          ),
          axios.get(`${baseURL}/get_project_list`),
          axios.get(`${baseURL}/get_designer_list`),
          axios.get(`${baseURL}/get_client_list`),
          axios.get(`${baseURL}/get_project_engineer_list`),
          axios.get(`${baseURL}/get_lifting_points`),
          axios.get(`${baseURL}/get_connection_types`),
          axios.get(`${baseURL}/get_engineering_testing_certification`),
          axios.get(`${baseURL}/get_installation_and_deliveryChecks`),
          axios.get(`${baseURL}/get_design_standards`),
          axios.get(`${baseURL}/get_approval_history?CaseKey=${CASE_ID}`),
        ]);
        mergeData(overviewRes, specRes, designInfoRes, tradePartnerRes);
        setprojectData(projectRes.data["project_list"] || []);
        setdesignerData(designRes.data["designer_list"] || []);
        setSelectedClient(clientRes.data["client_list"] || []);
        setProjectEngineer(engineerRes.data["project_engineer_list"] || []);
        setLiftingPoint(liftingPointRes.data["lifting_points"] || []);
        setConnectionTypes(connectionTypesRes.data["connection_types"] || []);
        setEngTestCert(
          engtestcertRes.data["engineering_testing_certification"] || []
        );
        setInstallationDeliveryChecks(
          installationDeliveryChecksRes.data[
            "installation_and_deliveryChecks"
          ] || []
        );
        setDesignStandards(designStandardsRes.data["design_standards"] || []);
        setApprovalData(approvalHistoryRes.data["ApproversList"] || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const mergeData = (overviewRes, specRes, designInfoRes, tradePartnerRes) => {
    const temp = _.merge(
      overviewRes.data,
      specRes.data,
      designInfoRes.data,
      tradePartnerRes.data
    );
    setPyStatusWork(_.get(temp, "content.pyStatusWork"));
    setCompData(temp);
  };

  const getWithDefault = (object, path, defaultValue = "N/A") => {
    const value = _.get(object, path);
    return value === "" ? defaultValue : value;
  };
  const formModalHide = () => setFormModalState(false);
  const formModalShow = () => setFormModalState(true);
  const handleApprove = () => {
    axios
      .post(`${baseURL}/approval`, {
        //actionID: `${compData.actionID}`,
        actionID: "pyApproval",
        assignmentID: `${compData.assignmentID}`,
        comments: `${comment}`,
        pyApprovalResult: "Approved",
      })
      .then((res) => {
        console.log("response=>", res.data);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setApprovalModal(false);
        window.location.reload(true);
      });
  };
  const handleReject = () => {
    axios
      .post(`${baseURL}/approval`, {
        //actionID: `${compData.actionID}`,
        actionID: "pyApproval",
        assignmentID: `${compData.assignmentID}`,
        comments: `${rejectcomment}`,
        pyApprovalResult: "Rejected",
      })
      .then((res) => {
        console.log("response=>", res.data);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setRejectModal(false);
        window.location.reload(true);
      });
  };
  const handleComplete = async (e) => {
    const caseId = params.userId;
    try {
      const response = await axios.post(`${baseURL}/complete`, {
        processID: "JumpToArchivedStage",
        caseID: caseId.toString(),
      });
      if (response.status === 200 && response.statusText === "OK") {
        const data = response.data;
        // alert(data.confirmationNote);
        alert("Status has updated successfully");
        navigate("/hardhat/mycomponent");
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };

  // const getHistoryDetails = async () => {
  //   try {
  //     const config = {
  //       method: "POST",
  //       url: `${baseURL}/get_case_view_details`,
  //       headers: {
  //         // caseID: { caseID },
  //         // viewID: { viewID },
  //       },
  //       // data: body,
  //     };
  //     const response = await axios(config);
  //     console.log(response);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   {
  //     // Archived ? getHistoryDetails() : null;
  //   }
  // }, []);
  console.log(pyStatusWork);
  return (
    <>
      <div className="col-md-12 pb-4">
        <div className="row">
          <div className="col-md-12 border progressbar-wrapper">
            <div className="row">
              <div className="col-md-9">
                <ul className="steps">
                  <li
                    className="past"
                    style={{
                      backgroundColor:
                        pyStatusWork === "Work In Progress" ? "#06026e" : null,
                      color:
                        pyStatusWork === "Work In Progress" ? "#fff" : "#000",
                    }}
                  >
                    <span>Work in Progress</span>
                    <i></i>
                  </li>
                  <li className="present">
                    <span>Published to Digital Component Library</span>
                    <i></i>
                  </li>
                  <li
                    className="future"
                    style={{
                      backgroundColor:
                        pyStatusWork === "Archived" ? "#06026e" : null,
                      color: pyStatusWork === "Archived" ? "#fff" : "#000",
                    }}
                  >
                    <span>Archived</span>
                    <i></i>
                  </li>
                </ul>
              </div>

              <div className="col-md-3 mark-btn">
                {pyStatusWork === "Archived" ? null : (
                  <button
                    className="btn btn-primary w-100"
                    onClick={handleComplete}
                    disabled={pyStatusWork === "Archived"}
                  >
                    Mark Status as Completed
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="row pt-2 pb-2">
          <div className="col-md-4 d-flex pt-2">
            <div className="icon-area">
              <img src={ComponentIconWearhouse} alt="" height={33} />
            </div>
            <div className="component-heading-detail">
              <h6 className="p-0">My Projects</h6>
              <p>{user}</p>
            </div>
          </div>
          <div className="col-md-8 pt-2" style={{ textAlign: "end" }}>
            <div className=" text-end mt-1 new-component-btn-detail">
              <button type="button" className="btn btn-primary">
                Follow
              </button>
              <button type="button" className="btn btn-primary">
                Edit
              </button>
              <button type="button" className="btn btn-primary">
                Submit For Approval
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div className="component-heading-below d-flex pt-4">
          <div className="designer-container component-heading-inner component-heading-projectname">
            <h6>Designer</h6>
            <p>{getWithDefault(compData, "mapping.Designer")}</p>
          </div>
          <div className="record_type-container component-heading-inner">
            <h6>Record Type</h6>
            <p>{getWithDefault(compData, "mapping.ComponentType")}</p>
          </div>
          <div className="category-container component-heading-inner">
            <h6>Category</h6>
            <p>{getWithDefault(compData, "mapping.ComponentCategoryID")}</p>
          </div>
          <div className="sub_category-container component-heading-inner">
            <h6>Sub-Category</h6>
            <p>{getWithDefault(compData, "mapping.ComponentSubcategoryID")}</p>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div className="row">
          <div className="col-md-7 pb-5">
            <div>
              <ul
                className="nav nav-pills mb-2 border-bottom border-2"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item " role="presentation">
                  <div className="tab-area-top">
                    <button
                      className="nav-link text-primary fw-semibold active position-relative"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Details
                    </button>
                  </div>
                </li>
                <li className="nav-item" role="presentation">
                  <div className="tab-area-top">
                    <button
                      className="nav-link text-primary fw-semibold position-relative"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Files
                    </button>
                  </div>
                </li>
                <li className="nav-item" role="presentation">
                  <div className="tab-area-top">
                    <button
                      className="nav-link text-primary fw-semibold position-relative"
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      Notes
                    </button>
                  </div>
                </li>
              </ul>
              <div className="tab-content p-2" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="tab-content-area">
                    <Accordion defaultActiveKey={["0"]} alwaysOpen>
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <form>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <div className="form-heading">
                                {/* <p>Component Detail</p> */}
                                <p>Overview</p>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="row">
                                <div className="col mb-2 formmodel-label-edit">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Component"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentDetails.Component"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 formmodel-label-edit">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="ComponentType"
                                    value={getWithDefault(
                                      compData,
                                      "mapping.ComponentType"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-2 formmodel-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Category"
                                    value={getWithDefault(
                                      compData,
                                      "mapping.ComponentCategoryID"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Project List"
                                    value={getWithDefault(
                                      compData,
                                      "mapping.Project"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-2 formmodel-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Subcategory"
                                    value={getWithDefault(
                                      compData,
                                      "mapping.ComponentSubcategoryID"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 formmodel-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Designer"
                                    value={getWithDefault(
                                      compData,
                                      "mapping.Designer"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-2 formmodel-label-edit">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Description"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentDetails.Description"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 formmodel-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Volume"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.VolumeM3"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-2 formmodel-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Product ID"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentDetails.ProductID"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 formmodel-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Product Name"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentDetails.ProductName"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Trade Partner"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentDetails.PreferredTradePartnerContactID"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Project Engineer"
                                    value={getWithDefault(
                                      compData,
                                      "mapping.ProjectEngineer"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Design Company"
                                    value={getWithDefault(
                                      compData,
                                      "mapping.DesignCompany"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Client"
                                    value={getWithDefault(
                                      compData,
                                      "mapping.Client"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Status"
                                    value={getWithDefault(
                                      compData,
                                      "content.pyStatusWork"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label"></div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              <div className="form-heading">
                                <p>Concrete Specification</p>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="row">
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="X (mm)"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.Xmm"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Y (mm)"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.Ymm"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Specification"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.Specification"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Reinforcement"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.Reinforcement"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Z1 (mm)"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.Z1mm"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Z2 (mm)"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.Z2mm"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Bonding"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.Bonding"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="RebatesPenetrations (mm3)"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.RebatesPenetrationsmm3"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Inserts"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.Inserts"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label"></div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="2">
                            <Accordion.Header>
                              <div className="form-heading">
                                <p>Design Partner Information Request</p>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="row">
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Security Rating"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.SecurityRating"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Reference Drawing"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.ReferenceDrawing"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Fire Rating"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.FireRating"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Design Standards"
                                    value={getWithDefault(
                                      compData,
                                      "mapping.DesignStandards"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Blast Rating"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.BlastRating"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Engineering Testing Certification"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.EngineeringTestingCertification"
                                    )}
                                  />
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="3">
                            <Accordion.Header>
                              <div className="form-heading">
                                <p>Trade Partner Information Request</p>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="row">
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Handover Information"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.HandoverInformation"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Lifting Points"
                                    value={getWithDefault(
                                      compData,
                                      "mapping.LiftingPoints"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Material Handling"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.MaterialHandling"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Connection Type"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.ConnectionType"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Installation and Delivery Checks"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.InstallationDeliveryChecks"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label">
                                  {/* <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Connection Type"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.ConnectionType"
                                    )}
                                  /> */}
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="4">
                            <Accordion.Header>
                              <div className="form-heading">
                                <p>Information</p>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="row">
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Cross Sectional Area (m2)"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.CrossSectionalAream2"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Approximate Mass (kg)"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.ApproximateMasskg"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Diameter (mm)"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.Diametermm"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Landing 1 Length (M)"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.Landing1LengthM"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Perimeter (m2)"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.Perimeterm2"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Landing 2 Length (M)"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.Landing2LengthM"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Surface Area (m2)"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.SurfaceAream2"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Stair Width (mm)"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.StairWidthmm"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Thickness (mm)"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.Thicknessmm"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Tread Depth (mm)"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.TreadDepthmm"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Uniclass (mm)"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.Uniclass"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Tread Rise (mm)"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.TreadRisemm"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Width (mm)"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.Widthmm"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Length (mm)"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.Lengthmm"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Height (mm)"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.Heightmm"
                                    )}
                                  />
                                </div>
                                <div className="col mb-2 tabform-label">
                                  <ComponentField
                                    status={pyStatusWork}
                                    formModalShow={formModalShow}
                                    label="Weight (kg)"
                                    value={getWithDefault(
                                      compData,
                                      "content.ComponentConfigurationDetails.Weightkg"
                                    )}
                                  />
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </form>
                      )}
                    </Accordion>
                  </div>
                  {pyStatusWork !== "Archived" ? (
                    <EditFormModal
                      show={formModalState}
                      onHide={formModalHide}
                      compData={compData}
                      designersData={designersData}
                      projectsData={projectsData}
                      clientData={clientData}
                      projectEngineer={projectEngineer}
                      liftingPointData={liftingPointData}
                      connectionTypesData={connectionTypesData}
                      engTestCertData={engTestCertData}
                      designStandards={designStandards}
                      installationDeliveryChecksData={
                        installationDeliveryChecksData
                      }
                    />
                  ) : null}
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="content-area"></div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-contact"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  <div className="content-area"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <div className="">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-7">
                      <div className="approval-area d-flex">
                        <div className="approval-icon">
                          <img src={UserIconCard} alt="" height={33} />
                        </div>
                        <div className="approval-name">
                          {approvalData && (
                            <p>Approval History ({approvalData.length})</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-5 p-0">
                      <div className="aprroval-dropdown d-flex">
                        <div className="first">
                          {pyStatusWork === "Archived" || pyStatusWork === "Rejected" || pyStatusWork === "Shared for Review"  ? null : (
                            <button
                              className="btn btn-approve"
                              type="button"
                              // id="dropdownMenuButton1"
                              // data-bs-toggle="dropdown"
                              // aria-expanded="false"
                              onClick={() => setApprovalModal(true)}
                              disabled={pyStatusWork === "Archived"}
                            >
                              Approve
                            </button>
                          )}

                          {pyStatusWork === "Archived" || pyStatusWork === "Rejected" || pyStatusWork === "Shared for Review"  ? null : (
                            <button
                              className="btn btn-reject"
                              type="button"
                              onClick={() => setRejectModal(true)}
                              disabled={pyStatusWork === "Archived"}
                            >
                              Reject
                            </button>
                          )}

                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <div className="dropdown-item" href="#">
                                Approved
                              </div>
                            </li>
                            <li>
                              <div className="dropdown-item" href="#">
                                Reject
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="aproved-status-table">
                    <table className="table table-borderless status-table">
                      <thead>
                        <tr>
                          <th scope="col">Step Name</th>
                          <th scope="col">Date</th>
                          <th scope="col">Status</th>
                          <th scope="col">Assigned To</th>
                        </tr>
                      </thead>
                      <tbody>
                        {approvalData &&
                          approvalData.map((i) => {
                            return (
                              <tr>
                                <th scope="row">{i.Role}</th>
                                <td>{isoToLocalDateFormat(i.UpdatedDateTime)}</td>
                                <td>{i.Status}</td>
                                <td>
                                  <div className="approval-table-inside-btn">
                                    <div className="dropdown">
                                      <button
                                        className="btn btn-outline dropdown-toggle w-100"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        {i.Name}
                                      </button>
                                      <ul
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton1"
                                      >
                                        <li>
                                          <div
                                            className="dropdown-item"
                                            href="#"
                                          >
                                            Approve
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            className="dropdown-item"
                                            href="#"
                                          >
                                            Reject
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ApprovalModal
        show={showApprovalModal}
        onHide={approvalModalClose}
        // selection={selection}
        setdata={setComment}
        handleSumbit={handleApprove}
        // captureResponse={captureResponse}
        // caseDesc={caseDesc}
      />
      <RejectModal
        show={showRejectModal}
        onHide={rejectModalClose}
        // selection={selection}
        setdata={setRejectComment}
        handleSumbit={handleReject}
        // captureResponse={captureResponse}
        // caseDesc={caseDesc}
      />
    </>
  );
}

export default HardhatProjectDetail;
