import React from "react";
import "../../ScheduleDuration/Ideate/Ideate.css";
import Header from "../../ScheduleDuration/Header/Header";
import ScheduleSidebar from "../../ScheduleDuration/ScheduleSidebar/ScheduleSidebar";
import SubHeader from "../SubHeader/SubHeader";

function Ideate() {
  return <div className="col-11 p-0 durationreview_areaa"></div>;
}

export default Ideate;
