import React from "react";
import { clearBothStorages } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { removeChatbotScripts } from "../../utils/utils";

function Logout() {
  const navigate = useNavigate();
  useEffect(() => {
    clearBothStorages();
    removeChatbotScripts();
    navigate("/");
  }, []);

  return <></>;
}

export default Logout;
