import { Grid, IconButton } from "@mui/material";
import Icons from "../../components/Icons";
import { showDrawer } from "../../store/reducers/common/navDrawerSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  AppHeaderStyle as AppHeader,
  HeaderCta,
  HeaderMain,
  MenuIcon,
} from "./styles";
import SearchField from "../../components/forms/Search";
import Button from "../../components/Button";

// export default function Layout(props: PageLayoutPropsTypes){
export default function Header() {
  const dispatch = useDispatch();
  const isNavEnabled = useSelector((state) => state.navDrawer.enableNavDrawer);

  return (
    <AppHeader>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={6}>
          <HeaderMain>
            {!isNavEnabled && (
              <MenuIcon onClick={() => dispatch(showDrawer())}>
                <Icons name="logoSmall" />
              </MenuIcon>
            )}
            <SearchField placeholder="Search or Ask 2BOS" />
          </HeaderMain>
        </Grid>
        <Grid item xs={6} textAlign="right">
          <HeaderCta>
            <IconButton sx={{ ml: 0.5 }}>
              <Icons name="settings" />
            </IconButton>
            <IconButton sx={{ ml: 0.5 }}>
              <Icons name="bell" />
            </IconButton>
            <Button
              variant="contained"
              startIcon={<Icons name="wave" />}
              sx={{ ml: 2, mr: 6, fontSize: 14 }}
              size="medium"
            >
              Ask 2BOS
            </Button>
          </HeaderCta>
        </Grid>
      </Grid>
    </AppHeader>
  );
}
