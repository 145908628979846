import React from "react";
import _, { values } from "lodash";
import "./Setup.scss";
import {
  Tabs,
  Card,
  CardContent,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Box,
  CardActions,
  CardHeader,
  Typography,
  Tab,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

function TreeStructure({ qRes }) {
  const [childTab, setChildTab] = React.useState(0);
  const handleChange = (event, newValue) => {
    setChildTab(newValue);
  };
  console.log(qRes);
  const leafNode = (value) => {
    return value.map((dataTree, indx) => {
      return (
        <div className="treeData">
          <p>{dataTree}</p>
        </div>
      );
    });
  };
  return (
    <Box className="template_tab tree_data_list">
      <TabContext value={childTab}>
        <TabList
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={handleChange}
          className="my_tree_tab"
        >
          {Object.entries(qRes).map(([key], id) => (
            <Tab wrapped label={key} value={id} />
          ))}
        </TabList>
        {Object.entries(qRes).map(([key, value], id) => (
          <TabPanel value={id}>{leafNode(value)}</TabPanel>
        ))}
      </TabContext>
    </Box>
  );
}

export default TreeStructure;
