import React, { useContext, useEffect, useState } from "react";
import "../Hardhat/Hardhat.css";
import ComponentIconWearhouse from "../../assets/component-icon-wearhouse.png";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import MOCK_DATA from "./MOCK_DATA.json";
import CREATE_CASE_MOCK_DATA from "./CREATE_CASE_MOCK_RES.json";
import { Plus } from "react-bootstrap-icons";
import { GlobalFilter } from "./GlobalFilter";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import MyModal from "./MyModal";
import FormModal from "./FormModal";
import Loader from "../../components/UI/Loader";
import { AppContext } from "../../Context/Appstateprovider";
import axios from "axios";
import HardhatHelp from "./HardhatHelp";
import ProtectedRoute from "../../components/HOC/protectedRoutes";

function HeadhatTable({ value }) {
  // const baseURL = process.env.REACT_APP_API_URL;
  const baseURL = "https://backend.sandbox-01.2bos.ai/api";
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  console.log(data);
  // const { columns, setColumns, data, setData, globalFilter,  } = useContext(AppContext);
  const [captureResponse, setCaptureResponse] = useState({});

  const [createCaseData, setcreateCaseData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);

  const [formModalOpen, FormhandleClose] = useState(false);
  const [selection, setSelection] = useState("");
  const [caseDesc, setCaseDesc] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const formHandleClose = () => FormhandleClose(false);
  const formHandleShow = () => FormhandleClose(true);

  const processResponseData = (columns, data) => {
    const iD = {
      Header: "Id",
      accessor: "id",
      Cell: ({ row }) => row.index + 1,
    };
    const processedColumns = columns.map((column) => {
      if (column.Header === "Component") {
        return {
          Header: "Component",
          accessor: "ComponentID",
          Cell: ({ row }) => {
            return (
              <Link
                className="id-name"
                to={`/hardhat/mycomponent/${row.original.pzInsKey}`}
              >
                {row.original.ComponentID}
              </Link>
            );
            //   if (row.original.pyStatusWork !== "Archived") {
            //     return (
            //       <Link
            //         className="id-name"
            //         to={`/hardhat/mycomponent/${row.original.pzInsKey}`}
            //       >
            //         {row.original.ComponentID}
            //       </Link>
            //     );
            // } else {
            //   return row.original.ComponentID;
            // }
          },
        };
      } else {
        return column;
      }
    });
    return [[iD, ...processedColumns], [...data]];
  };

  const wait = (delay) => {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  };

  //

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await wait(500);
      try {
        const response = await axios.get(`${baseURL}/get_component_list`);
        const [columns, rows] = processResponseData(
          response.data.columns,
          response.data.rows
        );
        setData(rows);
        setColumns(columns);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  //

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  useEffect(() => {
    setGlobalFilter(value);
  }, [value]);

  const createCase = async () => {
    const { actionID = "", assignmentID = "", etag = "" } = createCaseData;
    const body = {
      actionID,
      assignmentID,
      etag,
      componentType: selection,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    const captureResponse = await axios.post(
      `${baseURL}/capture_and_perform_action`,
      body,
      { headers }
    );
    setCaptureResponse(captureResponse.data);
    return;
  };

  if (isLoading) return <Loader />;
  const ModalHandler = () => {
    createCase();
    if (selection === "PRECON") {
      handleClose();
      formHandleShow();
    }
    if (selection === "RDPV") {
      handleClose();
      formHandleShow();
    }
    if (selection === "RAILTRACK") {
      handleClose();
      formHandleShow();
    }
    if (selection === "SS") {
      handleClose();
      formHandleShow();
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <div className="row pt-2 pb-2">
            <div className="col-md-4 d-flex pt-2">
              <div className="icon-area">
                <img src={ComponentIconWearhouse} alt="" height={25} />
              </div>
              <div className="component-heading">
                <h6 className="p-0">My Components</h6>
              </div>
            </div>
            <div className="col-md-4 pt-2">
              {/* <div className="mb-2" width="100%">
              <GlobalFilter
                filter={globalFilter}
                setFilter={setGlobalFilter}
              />
            </div> */}
            </div>
            <div className="col-md-4 text-end mt-1 new-component-btn">
              <Button variant="primary" onClick={handleShow}>
                {/* <Plus size={28} /> */}
                New
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 component-table-area">
              <>
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? " 🔽"
                                  : " 🔼"
                                : ""}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            </div>
          </div>
          <MyModal
            show={show}
            onHide={handleClose}
            onClick={ModalHandler}
            setSelection={setSelection}
            selection={selection}
            setcreateCaseData={setcreateCaseData}
            setCaseDesc={setCaseDesc}
            createCaseData={createCaseData}
          />
          <FormModal
            show={formModalOpen}
            onHide={formHandleClose}
            selection={selection}
            setdata={setData}
            captureResponse={captureResponse}
            caseDesc={caseDesc}
          />
        </div>
        <div className="col-md-4" style={{ padding: "10px 15px !important" }}>
          <div className="pt-4">
            <div className="help-area">
              <div className="help-heading">
                <p style={{ fontWeight: "600", fontFamily: "Lato" }}>
                  Help: Designers
                </p>
              </div>
              <div className="help-points">
                <p style={{ color: "red", fontFamily: "Lato", fontSize: "14px" }}>
                  Fair Use Expectations
                </p>
                <p style={{ color: "red", fontFamily: "Lato", fontSize: "14px" }}>
                  How to submit new Components
                </p>
                <p style={{ color: "red", fontFamily: "Lato", fontSize: "14px" }}>
                  How does this Component Marketplace Work
                </p>
                <p style={{ color: "red", fontFamily: "Lato", fontSize: "14px" }}>
                  What are the advantages of reusable Components in Construction
                  projects?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeadhatTable;
