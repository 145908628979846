import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

function BarChat3() {
  const baseURL = "https://backend.sandbox-01.2bos.ai/api";
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const chartDataRes = await axios.get(`${baseURL}/work_volume_by_status`);
      setChartData(chartDataRes.data.data);
    };
    fetchData();
  }, []);

  function convert(data) {
    const result = {};
    data.forEach((item) => {
      const label = item["pyCaseTypeInformation:pyLabel"];
      const status = item["pyStatusWork"];
      const count = parseInt(item["kwksjdwsoasw1ym49lc"], 10);
      if (!result[label]) {
        result[label] = {
          name: label,
          "Pending-ResponsePlanning": 0,
          "Pending-RiskAssessment": 0,
          "Pending-RiskMitigation": 0,
        };
      }
      result[label][status] = count;
    });
    return Object.values(result);
  }
  const tempData = convert(chartData);

  return (
    <ResponsiveContainer width="100%" height={400}>
      {" "}
      <BarChart
        width={450}
        height={400}
        data={tempData}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={{ fontSize: 12 }} tickMargin={-2}>
          <Label
            value="Work Type"
            offset={0}
            position="insideBottom"
            dx={0}
            dy={3}
          />
        </XAxis>
        <YAxis
          label={{
            value: "Total Work Item",
            angle: -90,
            position: "insideLeft",
            dx: 15,
            dy: 50,
          }}
        />
        <Tooltip />
        {/* <Legend /> */}
        <Bar dataKey="Pending-RiskMitigation" stackId="a" fill="#8884d8" />
        <Bar dataKey="Pending-RiskAssessment" stackId="a" fill="#82ca9d" />
        <Bar dataKey="Pending-ResponsePlanning" stackId="a" fill="#ffc658" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default BarChat3;
