import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PieChartImg from "../../assets/pie-chart1.png";
import PieChartImg2 from "../../assets/pie-chart2.jpg";
import Homebg from "../../assets/2BOS-homebg.jpg";
import {
  getCurrentUrl,
  hasAndIsDefinedInSessionStorage,
  parseTokenFromUrl,
  setInSessionStorage,
} from "../../utils/utils";
import _ from "lodash";
import "../Home/Home.css";
import BarChat from "../BarChart/BarChat";
import BarChat2 from "../BarChart/BarChart2";
import {
  Tabs,
  Card,
  CardContent,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Box,
  Typography,
  Tab,
  IconButton,
  InputBase,
  Divider,
  Stack,
  Alert,
  Grid,
} from "@mui/material";

import headerBg from "../../assets/header-bg.svg";
import Icons from "../../components/Icons";
import Workspace from "../../components/tables/Workspace";
import Table from "../../assets/workspace-table.png";

function Home() {
  const navigate = useNavigate();
  // useEffect(() => {
  //   const url = parseTokenFromUrl(getCurrentUrl());
  //   if (!_.isEmpty(url)) {
  //     setInSessionStorage("accessToken", url["accessToken"]);
  //     setInSessionStorage("idToken", url["idToken"]);
  //     setInSessionStorage("tokenType", url["tokenType"]);
  //     setInSessionStorage("expiresIn", url["expiresIn"]);
  //   }
  //   if (
  //     !hasAndIsDefinedInSessionStorage("accessToken") &&
  //     !hasAndIsDefinedInSessionStorage("idToken")
  //   ) {
  //     navigate("/");
  //   }
  // }, []);

  return (
    <>
      <div className="rounded-lg w-full bg-cover bg-no-repeat bg-center px-4 py-4 bg-header-img mt-4 bg-black overflow-hidden mb-4">
        <h1 className="text-white text-3xl mb-1 font-bricolageGrotesque">
          Welcome back Sekhar
        </h1>
        <p className="text-white text-base mb-0">
          Discover what's possible by selecting a use case below.
        </p>
      </div>

      <div className="pb-3 pt-2">
        <h2 className="inline-flex items-center text-base mb-3 pl-1">
          <Icons name="star6" className="mr-2" />
          Recommended Templates
        </h2>
        {/* <div className="overflow-x-auto"> */}
        <div className="flex items-center space-x-4">
          <div className="transition-all duration-300 hover:bg-gradient-to-br hover:from-cyan-500 hover:via-rose-500 hover:to-violet-700 rounded-xl overflow-hidden p-[1px] bg-gray-300 hover:p-[2px] m-[1px] hover:m-0 hover:shadow-xl group">
            <div className=" bg-white cursor-pointer rounded-[11px] hover:rounded-[10px] p-4">
              <h3 className="group-hover:text-[#784EF5] text-base font-bold">
                Template 1{" "}
              </h3>
              <p className="text-xs mb-0 text-gray-500">
                Lorem ipsum dolor sit amet consectetur. Nascetur rhoncus sapien
                consequat{" "}
              </p>
            </div>
          </div>
          <div className="transition-all duration-300 hover:bg-gradient-to-br hover:from-cyan-500 hover:via-rose-500 hover:to-violet-700 rounded-xl overflow-hidden p-[1px] bg-gray-300 hover:p-[2px] m-[1px] hover:m-0 hover:shadow-xl group">
            <div className=" bg-white cursor-pointer rounded-[11px] hover:rounded-[10px] p-4">
              <h3 className="group-hover:text-[#784EF5] text-base font-bold">
                Template 2{" "}
              </h3>
              <p className="text-xs mb-0 text-gray-500">
                Lorem ipsum dolor sit amet consectetur. Nascetur rhoncus sapien
                consequat{" "}
              </p>
            </div>
          </div>
          <div className="transition-all duration-300 hover:bg-gradient-to-br hover:from-cyan-500 hover:via-rose-500 hover:to-violet-700 rounded-xl overflow-hidden p-[1px] bg-gray-300 hover:p-[2px] m-[1px] hover:m-0 hover:shadow-xl group">
            <div className=" bg-white cursor-pointer rounded-[11px] hover:rounded-[10px] p-4">
              <h3 className="group-hover:text-[#784EF5] text-base font-bold">
                Template 3{" "}
              </h3>
              <p className="text-xs mb-0 text-gray-500">
                Lorem ipsum dolor sit amet consectetur. Nascetur rhoncus sapien
                consequat{" "}
              </p>
            </div>
          </div>
          <div className="transition-all duration-300 hover:bg-gradient-to-br hover:from-cyan-500 hover:via-rose-500 hover:to-violet-700 rounded-xl overflow-hidden p-[1px] bg-gray-300 hover:p-[2px] m-[1px] hover:m-0 hover:shadow-xl group">
            <div className=" bg-white cursor-pointer rounded-[11px] hover:rounded-[10px] p-4">
              <h3 className="group-hover:text-[#784EF5] text-base font-bold">
                Template 4{" "}
              </h3>
              <p className="text-xs mb-0 text-gray-500">
                Lorem ipsum dolor sit amet consectetur. Nascetur rhoncus sapien
                consequat{" "}
              </p>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>

      <div className="pb-3 pt-3">
        <h2 className="inline-flex items-center justify-centerm text-base mb-3 pl-1">
          <Icons name="star1" className="mr-2" />
          This Workspace
        </h2>
        <img src={Table} className="w-full max-w-7xl" alt="Workspace Table" />
      </div>
    </>
  );
}

export default Home;
