import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { AddComment } from "@mui/icons-material";

const AddCommentComponent = () => {
  return (
    <FormControl sx={{ m: 1, width: "100%" }}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
      >
        <FormLabel>Add comment (optional)</FormLabel>
        <TextField
          placeholder=""
          multiline
          rows={6}
          fullWidth={true}
        />
      </Box>
    </FormControl>
  );
}

export default AddCommentComponent;