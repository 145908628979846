// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.documents-wrapper > .MuiBox-root > .MuiFormControl-root,
.links-wrapper > .MuiBox-root > .MuiFormControl-root {
    margin: 15px 0 10px !important;
}

svg.upload-icon {
    color:#918FA8 !important;
}

svg[data-testid=CloudUploadIcon] {
    color: transparent !important;    
}

.file-tag.pdf {
    background-color: #D92D20;
    color: #fff;
}

.file-tag.csv {
    background-color: #099250;
    color: #fff;
}

.file-tag.jpeg {
    background-color: #00D6BC;
    color: #fff;
}

.file-tag.png {
    background-color: #00D6BC;
    color: #fff;
}

.upload-wrapper {
    border: 1px solid rgba(0,0,0,0.3) !important;
}

.upload-wrapper + .MuiTypography-root {
    visibility: hidden;
}

.MuiTypography-root + .MuiBox-root > .MuiBox-root {
    width: 100%;
}

.MuiTypography-root + .MuiBox-root > .MuiBox-root:hover svg {
    opacity: 1 !important;
}

.upload-wrapper div.MuiTypography-root {
    margin: 0 auto !important;
}

.toggle-wrapper .upload-wrapper .MuiBox-root {
    border-radius: 4px;
    flex-direction: column;
    align-items: center;
    gap: 0;
}

.MuiButtonBase-root.MuiFab-root.MuiFab-circular {
    opacity: 1 !important;
    box-shadow: none !important;
    background-color: transparent;
    top: -2px;
    right: -5px;
}




`, "",{"version":3,"sources":["webpack://./src/pages/Hub/UploadStyle.css"],"names":[],"mappings":";AACA;;IAEI,8BAA8B;AAClC;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,4CAA4C;AAChD;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,mBAAmB;IACnB,MAAM;AACV;;AAEA;IACI,qBAAqB;IACrB,2BAA2B;IAC3B,6BAA6B;IAC7B,SAAS;IACT,WAAW;AACf","sourcesContent":["\n.documents-wrapper > .MuiBox-root > .MuiFormControl-root,\n.links-wrapper > .MuiBox-root > .MuiFormControl-root {\n    margin: 15px 0 10px !important;\n}\n\nsvg.upload-icon {\n    color:#918FA8 !important;\n}\n\nsvg[data-testid=CloudUploadIcon] {\n    color: transparent !important;    \n}\n\n.file-tag.pdf {\n    background-color: #D92D20;\n    color: #fff;\n}\n\n.file-tag.csv {\n    background-color: #099250;\n    color: #fff;\n}\n\n.file-tag.jpeg {\n    background-color: #00D6BC;\n    color: #fff;\n}\n\n.file-tag.png {\n    background-color: #00D6BC;\n    color: #fff;\n}\n\n.upload-wrapper {\n    border: 1px solid rgba(0,0,0,0.3) !important;\n}\n\n.upload-wrapper + .MuiTypography-root {\n    visibility: hidden;\n}\n\n.MuiTypography-root + .MuiBox-root > .MuiBox-root {\n    width: 100%;\n}\n\n.MuiTypography-root + .MuiBox-root > .MuiBox-root:hover svg {\n    opacity: 1 !important;\n}\n\n.upload-wrapper div.MuiTypography-root {\n    margin: 0 auto !important;\n}\n\n.toggle-wrapper .upload-wrapper .MuiBox-root {\n    border-radius: 4px;\n    flex-direction: column;\n    align-items: center;\n    gap: 0;\n}\n\n.MuiButtonBase-root.MuiFab-root.MuiFab-circular {\n    opacity: 1 !important;\n    box-shadow: none !important;\n    background-color: transparent;\n    top: -2px;\n    right: -5px;\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
