import React from 'react';
import "../../ScheduleDuration/DurationReview/DurationReview.css"
import Header from '../../ScheduleDuration/Header/Header';
import ScheduleSidebar from '../../ScheduleDuration/ScheduleSidebar/ScheduleSidebar';
import SubHeader from '../../ScheduleDuration/SubHeader/SubHeader';

function DurationReview({children}) {
  return (
    <div className='container-fluid'>
      <div className='row'>
        {/* <div className='col-12 p-0'>
          <Header />
        </div> */}
        <div className='col-12'>
          <div className='row'>
            <div className='col-1 p-0 sidebar-width'>
              <ScheduleSidebar />
            </div>
            <div className='col-11 p-0 durationreview_area'>
              {/* <div>
                <SubHeader />
              </div> */}
              <div>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}

export default DurationReview