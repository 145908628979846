// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .template-wrapper:has(.lex-web-ui-iframe){
display: none;
} */`, "",{"version":3,"sources":["webpack://./src/pages/ServicePortalTemplates/ServicePortalTemplates.css"],"names":[],"mappings":"AAAA;;GAEG","sourcesContent":["/* .template-wrapper:has(.lex-web-ui-iframe){\ndisplay: none;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
