import * as React from "react";
import {
  Paper,
  InputBase,
  Divider,
  IconButton,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  Button,
  CircularProgress,
} from "@mui/material";
import isURL from "validator/lib/isURL";
import CheckCircle from "./check-circle.svg";

const AddURLComponent = () => {
  const [url, setUrl] = React.useState("");
  const [validURL, setValidURL] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const getURL = (e) => {
    setUrl(e.target.value);
  };

  const verifyUrl = () => {
    setLoading(true);
    setValidURL(isURL(url));
    if (isURL(url)) {
      setLoading(false);
    }

    return validURL;
  };

  return (
    <FormControl sx={{ m: 1, width: "100%" }}>
      <FormLabel>URL (required)</FormLabel>
      <Grid
        container
        className={"add-url-wrapper"}
        sx={{
          form: {
            margin: "5px 10px 5px 0",
            borderColor: "rgba(0, 0, 0, 0.2)",
            boxShadow: "none",
            padding: "0px",
          },
        }}
      >
        <Grid item xs={10} md={10} className="file-name">
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              border: 1,
              borderColor: "#4E4E61",
            }}
          >
            <IconButton
              sx={{ p: "10px", padding: "6px", fontSize: "1rem" }}
              aria-label="menu"
            >
              {loading ? (
                <CircularProgress size={15} />
              ) : !validURL ? (
                "http://"
              ) : (
                <img src={CheckCircle} />
              )}
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder=""
              onChange={(e) => getURL(e)}
            />
          </Paper>
        </Grid>
        <Grid item xs={2} md={2}>
          <Stack spacing={0} direction="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              className="submit-buttons"
              sx={{
                color: "#784EF5",
                borderColor: "#784EF5",
                marginTop: "5px",
                padding: "5px 16px",
                width: "100%",
              }}
              onClick={verifyUrl}
            >
              Add
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default AddURLComponent;
