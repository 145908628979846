import React from 'react'

function HardhatHelp() {
  return (
    // <div>HardhatHelp</div>
    <div className='container pt-2'>
      <div className='row'>
        <div className='col-md-8 p-0'>
          <div className='help-area'>
            <div className='help-heading'>
              <p style={{fontWeight: "600", fontFamily: "Lato"}}>Help: Designers</p>
            </div>
            <div className='help-points'>
              <p style={{color: "red", fontFamily: "Lato"}}>Fair Use Expectations</p>
              <p style={{color: "red", fontFamily: "Lato"}}>How to submit new Components</p>
              <p style={{color: "red", fontFamily: "Lato"}}>How does this Component Marketplace Work</p>
              <p style={{color: "red", fontFamily: "Lato"}}>What are the advantages of reusable Components in Construction projects?</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HardhatHelp