import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import Icons from "../../../components/Icons";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

// export default function Layout(props: PageLayoutPropsTypes){
export default function WorkspaceMenu() {
  const [workspace, setWorkspace] = useState("wales");

  const handleChange = (event) => {
    setWorkspace(event.target.value);
  };

  const arrowIcon = () => (
    <Icons
      name="chevronDown"
      sx={{ maringRight: "10px", backgroundColor: "transparent" }}
    />
  );

  return (
    <FormControl
      variant="filled"
      sx={{
        minWidth: 120,
        border: "1px solid #918FA8",
        borderRadius: "6px",
        backgroundColor: "#fff",
        overflow: "hidden",
        minHeight: "54px",
        mb: 3,
        mt: 0.5,
      }}
    >
      <InputLabel
        id="workspace-label"
        sx={{
          textTransform: "uppercase",
          fontSize: 10,
          fontWeight: "bold",
          paddingTop: "6px",
        }}
      >
        Workspace
      </InputLabel>
      <Select
        labelId="workspace-label"
        id="workspace-select"
        value={workspace}
        onChange={handleChange}
        sx={{ backgroundColor: "#fff", minHeight: 57, fontSize: 14 }}
        IconComponent={(props) => (
          <ExpandMoreRoundedIcon
            {...props}
            style={{
              color: "#000",
              marginTop: "9px",
              // marginRight: "4px",
              fontSize: 22,
              strokeWidth: 0.5,
            }}
          />
        )}
      >
        <MenuItem value="wales" style={{ fontSize: "14px" }}>
          Prince of Wales Hospital
        </MenuItem>
        <MenuItem value="hospital1" style={{ fontSize: "14px" }}>
          Hospital 1
        </MenuItem>
        <MenuItem value="hospital2" style={{ fontSize: "14px" }}>
          Hospital 2
        </MenuItem>
      </Select>
    </FormControl>
    // <form className="w-full mb-6 relative">
    //   <label htmlFor="workSpace" className="absolute top-7 right-3">
    //     <Icons name="chevronDown" />
    //   </label>
    //   <label
    //     htmlFor="workSpace"
    //     className="block text-sm font-bold text-gray-500 dark:text-white pt-2 px-3 absolute"
    //     style={{ fontSize: "8px" }}
    //   >
    //     WORKSPACE
    //   </label>
    //   <select
    //     id="workSpace"
    //     className="text-gray-900 text-base block w-full pb-2 pl-2.5 pt-6 rounded-md border border-grey-800 border-solid custom-select"
    //     onChange={handleChange}
    //     value={workspace}
    //     style={{ paddingTop: "24px !important", paddingLeft: '10px !important'}}
    //   >
    //     <option value="wales">Prince of Wales Hospital</option>
    //     <option value="hospital1">Hospital 1</option>
    //     <option value="hospital2">Hospital 2</option>
    //   </select>
    // </form>
  );
}
