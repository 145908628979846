import React from 'react';
import "./ScheduleSidebar.css";
import { NavLink } from "react-router-dom";
import PageIcon from "../../../assets/page-icon.png";
import UploadIcon from "../../../assets/upload-icon.png";
import SquareComponents from "../../../assets/squarecomponents-icon.png";
import AppsIcon from "../../../assets/apps-icon.png";
import AppStylesIcon from "../../../assets/style-icon.png";
import TemplateIcon from "../../../assets/template-icon.png";


export default function ScheduleSidebar() {
  return (
    <div className='sidebar-internal-page'>
      <ul className='menu-inside-box'>
        <li><NavLink to="/pages" activeClassName='is-active'>
          <div className='menu-icon'>
            <img src={PageIcon} alt="" />
            <p>Pages</p>
          </div></NavLink>
        </li>
        <li><NavLink to="/durationreview/tempplate" activeClassName='is-active'>
          <div className='menu-icon'>
            <img src={TemplateIcon} alt="" />
            <p>Templates</p>
          </div></NavLink>
        </li>
        <li><NavLink to="/components" activeClassName='is-active'>
          <div className='menu-icon'>
            <img src={SquareComponents} alt="" />
            <p>Components</p>
          </div></NavLink>
        </li>
        <li><NavLink to="/style" activeClassName='is-active'>
          <div className='menu-icon'>
            <img src={AppStylesIcon} alt="" />
            <p>Styles</p>
          </div></NavLink>
        </li>
        <li><NavLink to="/upload" activeClassName='is-active'>
          <div className='menu-icon'>
            <img src={UploadIcon} alt="" />
            <p>Upload</p>
          </div></NavLink>
        </li>
        <li><NavLink to="/apps" activeClassName='is-active'>
          <div className='menu-icon'>
            <img src={AppsIcon} alt="" />
            <p>Apps</p>
          </div></NavLink>
        </li>
      </ul>
    </div>
  )
}

