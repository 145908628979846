// import { Logout } from "@mui/icons-material";
import { Logout } from "@mui/icons-material";
import { Avatar, List, ListItem, Typography } from "@mui/material";

import { NavLink } from "react-router-dom";
import Icons from "../../../components/Icons";

// export default function Layout(props: PageLayoutPropsTypes){
export default function LogoutSection() {
  return (
    <List
      className="d-flex align-items-center"
      style={{ paddingTop: 20, paddingBottom: 0, marginBottom: 0 }}
    >
      <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
        <NavLink
          rel="noreferrer"
          to="/logout"
          className="flex justify-center items-center"
        >
          <Avatar
            alt="Upadrasta, Sekhar"
            src="/static/images/avatar/1.jpg"
            sx={{ width: 30, height: 30 }}
            className="me-2"
          />
          <Typography
            className="d-flex flex-column transition-all"
            style={{ width: 145, overflow: "hidden" }}
          >
            <small
              style={{
                whiteSpace: "nowrap",
                width: 140,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <b>Upadrasta, Sekhar</b>
            </small>
            <small
              className="text-muted text-xs"
              style={{
                whiteSpace: "nowrap",
                width: 140,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              SekharUpadrasta@laingorourke.com.au
            </small>
          </Typography>
          {/* <Logout /> */}
          <Icons name="logout" stroke="#0d6efd" />
        </NavLink>
      </ListItem>
    </List>
  );
}
