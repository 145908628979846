export const mlData = [
    {

        "title": "Goals",
        "id": 1,
        "leaves": "This is Goals"
        // "Training and supervision" : [
        //     "Provide proper training and supervision to operators",
        //     "Hire experienced and skilled operators"
        // ]

    },
    {

        "title": "Calender",
        "id": 2,
        "leaves": "This is Games"
        // "Training and supervision" : [
        //     "Provide proper training and supervision to operators",
        //     "Hire experienced and skilled operators"
        // ]

    }
]
//     {
        
           
//         ],
//         "Equipment optimization": [
//             "Optimize equipment usage through planning and scheduling",
//             "Avoid idling and make best use of machinery"
//         ],
//         "Process improvement": [
//             "Implement lean construction techniques",
//             "Streamline workflows",
//             "Ensure timely material availability"
//         ],
//         "Technology adoption": [
//             "Use project management software",
//             "Monitor work to provide feedback"
//         ],
//         "Compliance and incentives": [
//             "Ensure compliance to construction standards",
//             "Incentivize workers for achieving targets"
//         ]
//     }
// ]