import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap/";
import Loader from "../../components/UI/Loader";
import "./../../index.css";
import axios from "axios";

export default function MyModal({
  show,
  onHide,
  onClick,
  setSelection,
  selection,
  setcreateCaseData,
  setCaseDesc,
  createCaseData = {},
}) {
  // const baseURL = process.env.REACT_APP_API_URL;
  const baseURL = "https://backend.sandbox-01.2bos.ai/api";
  const [isLoading, setIsLoading] = useState(false);
  const {
    Fields: { label = "", options = [] } = {},
    actionID = "",
    assignmentID = "",
    etag = "",
  } = createCaseData;
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const createCaseRes = await axios.get(`${baseURL}/create_case`);
      setcreateCaseData(createCaseRes.data);
      setIsLoading(false);
    };

    if (show) {
      fetchData();
    }
  }, [show]);

  const handleSelection = (d) => {
    setSelection(d.RefernceCode);
    setCaseDesc(d.ReferenceDescription);
  };

  const renderedData = () => {
    return (
      options &&
      options.map((d) => {
        return (
          <div className="form-check" key={d.RefernceCode}>
            <input
              className="form-check-input"
              type="radio"
              name="componentType"
              id={d.RefernceCode}
              value={d.RefernceCode}
              onChange={() => handleSelection(d)}
            />
            <label className="form-check-label" htmlFor={d.RefernceCode}>
              {d.ReferenceDescription}
            </label>
          </div>
        );
      })
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton> 
        <Modal.Title style={{ fontFamily: "Montserrat"}}>New Component</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontFamily: "Lato"}}>{isLoading ? <Loader /> : renderedData()}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onClick}>
          Next
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
