import React from "react";
import SubHeader from "../ScheduleDuration/SubHeader/SubHeader";
import "./ServicePortalTemplates.css";

function ServicePortalTemplates({ children }) {
  return (
    <div className="container-fluid" style={{ paddingTop: 40 }}>
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-12">
              <SubHeader />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-center">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicePortalTemplates;
