import React from "react";
import _, { values } from "lodash";
// import "../../ScheduleDuration/Setup/Setup.css";

function Tree2({ qRes }) {
  console.log(qRes);
  const leafNode = (value) => {
    return value?.map((v, indx) => {
      return (
        <div className="Goal-elements mb-3 pt-4">
          <div className="textbox-place d-flex">
            <p>{v}</p>
            {/* <button
              type="button"
              className="btn-close btn-close"
              aria-label="Close"
            ></button> */}
          </div>
        </div>
      );
    });
  };
  return (
    <>
      {Object.entries(qRes).map(([key, value]) => (
        <li key={key}>
          <div className="sub-box subheading-box">
            <div className="Goals-area subheading-p mb-3">
              <p>{key}</p>
            </div>
            {leafNode(value)}
          </div>
        </li>
      ))}
    </>
  );
}

export default Tree2;
