import * as React from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Box, Stack, Button } from "@mui/material";
import UploadFile from "./UploadFile2";
import AddURL from "./AddURL";
import FileType from "./FileType";
import AddComment from "./AddComment";
import AddTags from "./AddTags";
import CopyIcon from "./copy-01.svg";
import "./UploadStyle.css";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box component="h1" mb={0} fontSize={"1.2rem"} fontWeight={800}>
        Add a file
      </Box>
      <Box
        component="p"
        mt={0}
        mb={2}
        fontSize={"0.9rem"}
        sx={{ color: "#72708C" }}
      >
        Upload a document or link
      </Box>
      <Box
        sx={{
          width: "100%",
          ".MuiBox-root": {
            borderRadius: "7px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "10px",
            padding: "0",
            overflow: "hidden",
          },
          ".MuiTabs-fixed": {
            backgroundColor: "#E7E6F2",
          },
        }}
        className="toggle-wrapper"
      >
        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            ".MuiTabs-root": {
              minHeight: 0,
              height: "34px",
              backgroundColor: "#E7E6F2",
              padding: "0 2px",
            },
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="documents and links"
            sx={{
              ".MuiTabs-indicator": {
                backgroundColor: "transparent",
              },
              ".MuiButtonBase-root.MuiTab-root": {
                textTransform: "none",
              },
            }}
          >
            <Tab
              label="Document"
              {...a11yProps(0)}
              sx={{
                height: "30px",
                padding: "2px",
                margin: "2px 4px",
                flexBasis: "50%",
                maxWidth: "none",
                borderRadius: "5px",
                minHeight: 0,
                "&.Mui-selected": {
                  backgroundColor: "#fff",
                  borderRadius: "4px",
                },
              }}
              className="first-tab"
            />
            <Tab
              label="Links"
              {...a11yProps(1)}
              sx={{
                height: "30px",
                padding: "2px",
                margin: "2px 4px",
                flexBasis: "50%",
                maxWidth: "none",
                minHeight: "0",
                "&.Mui-selected": {
                  backgroundColor: "#fff",
                  borderRadius: "4px",
                },
              }}
              className="second-tab"
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0} className="documents-wrapper">
          <UploadFile />
          <FileType />
          <AddComment />
          <AddTags />
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                color: "#211F3D",
                borderColor: "#918FA8",
                "&:hover": {
                  borderColor: "#918FA8",
                },
              }}
            >
              <img
                src={CopyIcon}
                style={{ color: "#918FA8", marginRight: "10px" }}
              />{" "}
              Copy link
            </Button>
            <Button
              variant="contained"
              className="primary"
              sx={{
                textTransform: "none",
                backgroundColor: "#784EF5",
                "&:hover": {
                  backgroundColor: "#784EF5",
                },
              }}
            >
              Add file
            </Button>
          </Stack>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
          className="links-wrapper"
          sx={{
            ".MuiFormLabel-root.Mui-focused": {
              color: "rgba(0, 0, 0, 0.6)",
            },
          }}
        >
          <AddURL />
          <FileType />
          <AddComment />
          <AddTags />
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                color: "#211F3D",
                borderColor: "#918FA8",
                "&:hover": {
                  borderColor: "#918FA8",
                },
              }}
              className="submit-buttons"
            >
              <img
                src={CopyIcon}
                style={{ color: "#918FA8", marginRight: "10px" }}
              />{" "}
              Copy link
            </Button>
            <Button
              variant="contained"
              className="submit-buttons primary"
              sx={{
                textTransform: "none",
                backgroundColor: "#784EF5",
                "&:hover": {
                  backgroundColor: "#784EF5",
                },
              }}
            >
              Add resource
            </Button>
          </Stack>
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
