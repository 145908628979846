import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import Modal from "react-bootstrap/Modal";
import Loader from "../../components/UI/Loader";
import "./../../index.css";

function EditFormModal({
  show,
  onHide,
  compData,
  designersData,
  projectsData,
  clientData,
  projectEngineer,
  liftingPointData,
  connectionTypesData,
  engTestCertData,
  designStandards,
  installationDeliveryChecksData,
}) {
  // const baseURL = process.env.REACT_APP_API_URL;
  const baseURL = "https://backend.sandbox-01.2bos.ai/api";
  console.log(baseURL);
  let processedProjectLists = [];
  let processedDesignerLists = [];
  //
  const [selectedProj, setSelectedProj] = useState([]);
  const [selectedDesigner, setSelectedDesigner] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subcategoryData, setSubCategoryData] = useState([]);
  const [contentDetails, setContentDetails] = useState({
    SendEmailNow: false,
    ThisComponentRelatesToAProject: false,
    SampleRequired: false,
  });
  const [configurationDetails, setConfigurationDetails] = useState({
    DesignStandards: "",
    EngineeringTestingCertification: "",
    LiftingPoints: "",
    ConnectionType: "",
    InstallationDeliveryChecks: "",
    Xmm: "",
    Ymm: "",
    Specification: "",
    Z1mm: "",
    Reinforcement: "",
    Z2mm: "",
    Bonding: "",
    RebatesPenetrationsmm3: "",
    Inserts: "",
    Finish: "",
    CrossSectionalAream2: "",
    ApproximateMasskg: "",
    Diametermm: "",
    Landing1LengthM: "",
    Perimeterm2: "",
    Landing2LengthM: "",
    SurfaceAream2: "",
    StairWidthmm: "",
    Thicknessmm: "",
    TreadDepthmm: "",
    Uniclass: "",
    TreadRisemm: "",
    FireRating: "",
    Widthmm: "",
    Lengthmm: "",
    Heightmm: "",
    Weightkg: "",
    VolumeM3: "",
    SecurityRating: "",
    ReferenceDrawing: "",
    BlastRating: "",
    HandoverInformation: "",
    MaterialHandling: "",
    MSDS: false,
    UnitCost: "",
    InstallationCost: "",
    UnitManufacturingTime: "",
    VariationCost: "",
  });
  const [formData, setFormData] = useState({
    Component: "",
    ComponentCategoryID: "",
    ComponentSubcategoryID: "",
    Designer: "",
    Project: "",
    Description: "",
    ProductID: "",
    ProductName: "",
  });
  const [attachment, setAttachment] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const catRes = await axios.get(
          `${baseURL}/get_categories_list?RecordType=${getWithDefault(
            compData,
            "content.ComponentDetails.ComponentType"
          )}`
        );
        setCategoryData(catRes.data?.categories || []);
        setSelectedCategory(
          getWithDefault(
            compData,
            "content.ComponentDetails.ComponentCategoryID"
          )
        );
        const tempProj = _.find(projectsData, (obj) =>
          _.has(
            obj,
            getWithDefault(compData, "content.ComponentDetails.Project")
          )
        );
        if (tempProj) {
          let tempProjObj = {
            label: Object.values(tempProj)[0],
            key: Object.keys(tempProj)[0],
          };
          setSelectedProj([tempProjObj]);
        }
        const tempDesign = _.find(designersData, (obj) =>
          _.has(
            obj,
            getWithDefault(compData, "content.ComponentDetails.Designer")
          )
        );
        if (tempDesign) {
          let tempDesignObj = {
            label: Object.values(tempDesign)[0],
            key: Object.keys(tempDesign)[0],
          };
          setSelectedDesigner([tempDesignObj]);
        }
        setConfigurationDetails(
          _.get(compData, "content.ComponentConfigurationDetails")
        );
        setFormData(_.get(compData, "content.ComponentDetails"));
        setAttachment(_.get(compData, "content.ComponentImage"));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };
    if (show) fetchData();
  }, [show]);

  useEffect(() => {
    if (show && selectedCategory !== "" && selectedCategory !== "N/A") {
      const fetchsubCategoryData = async () => {
        const response = await axios.get(
          `${baseURL}/get_subcategories_list?RecordType=${getWithDefault(
            compData,
            "content.ComponentDetails.ComponentType"
          )}&Category=${selectedCategory}`
        );
        setSubCategoryData(response.data?.categories || []);
      };
      fetchsubCategoryData();
    }
  }, [selectedCategory]);

  const getWithDefault = (object, path, defaultValue = "N/A") => {
    const value = _.get(object, path);
    return value === "" ? defaultValue : value;
  };

  const handleConfigurationChange = (e) => {
    const { name, value } = e.target;
    console.log(name,value);
    if (name === "MSDS") {
      const { checked } = e.target;
      setConfigurationDetails((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setConfigurationDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleContentDetailsChange = (e) => {
    const { name, value } = e.target;
    if (name === "SampleRequired") {
      const { checked } = e.target;
      setContentDetails((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setContentDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    setSelectedCategory(value);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const loadCategory = () => {
    return categoryData.map((category) => {
      return (
        <option
          value={Object.keys(category)[0]}
          keys={Object.keys(category)[0]}
        >
          {Object.values(category)[0]}
        </option>
      );
    });
  };

  const loadSubCategory = () => {
    if (subcategoryData) {
      return subcategoryData.map((subcategory) => {
        return (
          <option
            value={Object.keys(subcategory)[0]}
            keys={Object.keys(subcategory)[0]}
          >
            {Object.values(subcategory)[0]}
          </option>
        );
      });
    }
  };

  const loadProjectsList = () => {
    return projectsData.map((project, indx) => (
      <option key={`project_${indx}`} value={Object.keys(project)[0]}>
        {Object.values(project)[0]}
      </option>
    ));
  };

  const loadDesignersList = () => {
    return designersData.map((designer, indx) => (
      <option key={`designer_${indx}`} value={Object.keys(designer)[0]}>
        {Object.values(designer)[0]}
      </option>
    ));
  };

  const loadClientList = () => {
    return clientData.map((client, indx) => (
      <option key={`client_${indx}`} value={Object.keys(client)[0]}>
        {Object.values(client)[0]}
      </option>
    ));
  };

  const loadProjectEngineerList = () => {
    return projectEngineer.map((projectEngineer, indx) => (
      <option
        key={`projectEngineer_${indx}`}
        value={Object.keys(projectEngineer)[0]}
      >
        {Object.values(projectEngineer)[0]}
      </option>
    ));
  };

  const loadLiftingPointLists = () => {
    return liftingPointData.map((liftingPoint, indx) => (
      <option key={`liftingPoint_${indx}`} value={Object.keys(liftingPoint)[0]}>
        {Object.values(liftingPoint)[0]}
      </option>
    ));
  };

  const loadConnectionTypesLists = () => {
    return connectionTypesData.map((connection_types, indx) => (
      <option
        key={`connection_types_${indx}`}
        value={Object.keys(connection_types)[0]}
      >
        {Object.values(connection_types)[0]}
      </option>
    ));
  };

  const loadEngTestCertLists = () => {
    return engTestCertData.map((engTestCert, indx) => (
      <option key={`engTestCert_${indx}`} value={Object.keys(engTestCert)[0]}>
        {Object.values(engTestCert)[0]}
      </option>
    ));
  };

  const loadDesignStandardsLists = () => {
    return designStandards.map((designStandard, indx) => (
      <option
        key={`designStandard_${indx}`}
        value={Object.keys(designStandard)[0]}
      >
        {Object.values(designStandard)[0]}
      </option>
    ));
  };

  const loadInstallationDeliveryChecksLists = () => {
    return installationDeliveryChecksData.map(
      (installationDeliveryChecks, indx) => (
        <option
          key={`installationDeliveryChecks_${indx}`}
          value={Object.keys(installationDeliveryChecks)[0]}
        >
          {Object.values(installationDeliveryChecks)[0]}
        </option>
      )
    );
  };

  const processProjectAndDesignerData = () => {
    processedProjectLists = [];
    processedDesignerLists = [];
    projectsData.map((project) => {
      let tempProjObj = {
        label: Object.values(project)[0],
        key: Object.keys(project)[0],
      };
      processedProjectLists.push(tempProjObj);
    });
    designersData.map((designer) => {
      let tempDesignObj = {
        label: Object.values(designer)[0],
        key: Object.keys(designer)[0],
      };
      processedDesignerLists.push(tempDesignObj);
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let processedConfigurationDetails = { ...configurationDetails };
    delete processedConfigurationDetails["classID"];
    let processedFormdata = { ...formData };
    delete processedFormdata["classID"];
    delete processedFormdata["PreferredTradePartnerID"];
    delete processedFormdata["ComponentType"];
    if (selectedProj.length > 0) {
      processedFormdata = {
        ...processedFormdata,
        Project: selectedProj[0].key,
      };
    }
    if (selectedDesigner.length > 0) {
      processedFormdata = {
        ...processedFormdata,
        Designer: selectedDesigner[0].key,
      };
    }
    const { actionID = "", assignmentID = "", caseID = "" } = compData;
    const body = {
      actionID,
      assignmentID,
      caseID,
      content: {
        ComponentConfigurationDetails: {
          ...processedConfigurationDetails,
        },
        ComponentDetails: {
          ...processedFormdata,
        },
        ...contentDetails,
      },
      pageInstructions: [],
    };
    // if (attachment) {
    //   body["attachments"] = attachment;
    // }
    try {
      const config = {
        method: "POST",
        url: `${baseURL}/update_assignment_action`,
        headers: {
          "Content-Type": "application/json",
        },
        data: body,
      };
      const response = await axios(config);
      console.log(response);
      if (response.status === 200 && response.statusText === "OK") {
        onHide();
        window.location.reload(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  processProjectAndDesignerData();
  // console.log("configurationDetails:", configurationDetails);
  // console.log("formData:", formData);
  // console.log("attachment:", attachment);

  // console.log("Component Data:", compData);
  // console.log("Category Data:", categoryData);
  // console.log("Designers Data:", designersData);
  // console.log("Projects Data:", projectsData);
  // console.log("Client Data:", clientData);
  // console.log("Project Engineer:", projectEngineer);
  // console.log("Lifting Point Data:", liftingPointData);
  // console.log("Connection Types Data:", connectionTypesData);
  // console.log("Engineering Test Certificate Data:", engTestCertData);
  // console.log("Design Standards:", designStandards);
  // console.log(
  //   "Installation & Delivery Checks Data:",
  //   installationDeliveryChecksData
  // );

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="form-box modal-lg"
      style={{
        height: "",
        width: "100%",
        backGround: "#fff",
        overflow: "hidden",
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="ps-2">New Component</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <Loader />
        ) : (
          <div
            className="col-md-12"
            style={{
              overflow: "scroll",
              overflowY: "scroll",
              overflowX: "hidden",
              height: "400px",
              marginLeft: "15.7px",
              paddingRight: "21px",
            }}
          >
            <form>
              <div className="form-heading">
                <p>Component Detail</p>
              </div>
              <div className="row">
                <div className="col mb-2 formmodel-label">
                  <label htmlFor="component" className="form-label">
                    Component<span style={{color: "red"}}>&nbsp;*</span>
                  </label>
                  <input
                    type="text"
                    name="Component"
                    className="form-control"
                    id="Component"
                    aria-describedby="component"
                    onChange={handleChange}
                    value={_.get(formData, "Component")}
                  />
                </div>
                <div className="col mb-2 formmodel-label">
                  <label htmlFor="ComponentType" className="form-label">
                    Record Type
                  </label>
                  <input
                    type="name"
                    id="ComponentType"
                    name="ComponentType"
                    className="form-control"
                    placeholder="Record Type"
                    value={_.get(formData, "ComponentType")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mb-2 formmodel-label">
                  <label htmlFor="ComponentCategoryID" className="form-label">
                    Category<span style={{color: "red"}}>&nbsp;*</span>
                  </label>
                  <select
                    id="ComponentCategoryID"
                    className="form-select"
                    name="ComponentCategoryID"
                    value={_.get(formData, "ComponentCategoryID")}
                    onChange={(e) => handleCategoryChange(e)}
                  >
                    <option selected>--None--</option>
                    {loadCategory()}
                  </select>
                </div>
                <div className="col mb-2 formmodel-label">
                  <label htmlFor="Project" className="form-label">
                    Project List
                  </label>
                  <Typeahead
                    id="Project"
                    onChange={setSelectedProj}
                    options={processedProjectLists}
                    placeholder="Search a project..."
                    selected={selectedProj}
                    value={_.get(formData, "Project")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mb-2 formmodel-label">
                  <label
                    htmlFor="ComponentSubcategoryID"
                    className="form-label"
                  >
                    Sub-Category
                  </label>
                  <select
                    id="ComponentSubcategoryID"
                    className="form-select"
                    name="ComponentSubcategoryID"
                    onChange={handleChange}
                    value={_.get(formData, "ComponentSubcategoryID")}
                  >
                    <option selected>--None--</option>
                    {loadSubCategory()}
                  </select>
                </div>
                <div className="col mb-2 formmodel-label">
                  <label htmlFor="Designer" className="form-label">
                    Designer
                  </label>
                  <Typeahead
                    id="Designer"
                    onChange={setSelectedDesigner}
                    options={processedDesignerLists}
                    placeholder="Search a Designer..."
                    selected={selectedDesigner}
                    value={_.get(formData, "Designer")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mb-2 formmodel-label">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <textarea
                    className="form-control"
                    rows="3"
                    id="Description"
                    type="text"
                    name="Description"
                    onChange={handleChange}
                    value={_.get(formData, "Description")}
                  ></textarea>
                </div>
                <div className="col mb-2 tabform-label">
                  <label htmlFor="volume" className="form-label">
                    Volume
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="VolumeM3"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "VolumeM3")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mb-2 tabform-label">
                  <label htmlFor="productid" className="form-label">
                    Product ID
                  </label>
                  <input
                    type="text"
                    name="ProductID"
                    className="form-control"
                    id="ProductID"
                    onChange={handleChange}
                    value={_.get(formData, "ProductID")}
                  />
                </div>
                <div className="col mb-2 tabform-label">
                  <label htmlFor="productname" className="form-label">
                    Product Name
                  </label>
                  <input
                    type="text"
                    name="ProductName"
                    className="form-control"
                    id="ProductName"
                    onChange={handleChange}
                    value={_.get(formData, "ProductName")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mb-2">
                  <label
                    htmlFor="formFile"
                    className="form-label tabform-label"
                  >
                    Default file input example
                  </label>
                </div>
                <div className="col mb-2 tabform-label">
                  <label
                    htmlFor="PreferredTradePartnerContactID"
                    className="form-label"
                  >
                    Trade Partner Contact
                  </label>
                  <select
                    id="PreferredTradePartnerContactID"
                    className="form-select"
                    name="PreferredTradePartnerContactID"
                    value={_.get(formData, "PreferredTradePartnerContactID")}
                    // onChange={(e) => handleCategoryChange(e)}
                  >
                    <option selected>--None--</option>
                    {/* {loadCategory()} */}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col mb-2 tabform-label">
                  <label htmlFor="ProjectEngineer" className="form-label">
                    Project Engineer
                  </label>
                  <select
                    id="ProjectEngineer"
                    className="form-select"
                    name="ProjectEngineer"
                    onChange={handleChange}
                    value={_.get(formData, "ProjectEngineer")}
                  >
                    <option selected>--None--</option>
                    {loadProjectEngineerList()}
                  </select>
                </div>
                <div className="col mb-2 tabform-label">
                  <label htmlFor="DesignCompany" className="form-label">
                    Design Company
                  </label>
                  <select
                    id="DesignCompany"
                    name="DesignCompany"
                    className="form-select"
                    onChange={handleChange}
                    value={_.get(formData, "DesignCompany")}
                  >
                    <option selected>--None--</option>
                    {loadDesignersList()}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col mb-2 tabform-label">
                  <label htmlFor="Client" className="form-label">
                    Client
                  </label>
                  <select
                    id="Client"
                    name="Client"
                    className="form-select"
                    onChange={handleChange}
                    value={_.get(formData, "Client")}
                  >
                    <option selected>--None--</option>
                    {loadClientList()}
                  </select>
                </div>
                <div className="col"></div>
              </div>
              <div className="form-heading pt-3 pb-3">
                <p>Concrete Specification</p>
              </div>
              <div className="row">
                <div className="col mb-2 tabform-label">
                  <label htmlFor="Xmm" className="form-label">
                    X (mm)
                  </label>
                  <input
                    type="text"
                    id="Xmm"
                    name="Xmm"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "Xmm")}
                    className="form-control"
                  />
                </div>
                <div className="col mb-2 tabform-label">
                  <div className="form-check">
                    <br></br>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="SampleRequired"
                      name="SampleRequired"
                      onChange={handleContentDetailsChange}
                      checked={getWithDefault(
                        compData,
                        "content.SampleRequired"
                      )}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="SampleRequired"
                    >
                      Sample Required
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col mb-2 tabform-label">
                  <label htmlFor="Ymm" className="form-label">
                    Y (mm)
                  </label>
                  <input
                    type="text"
                    id="Ymm"
                    name="Ymm"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "Ymm")}
                    className="form-control"
                  />
                </div>
                <div className="col mb-2 tabform-label">
                  <label htmlFor="Specification" className="form-label">
                    Specification
                  </label>
                  <input
                    type="text"
                    id="Specification"
                    name="Specification"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "Specification")}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mb-2 tabform-label">
                  <label htmlFor="Z1mm" className="form-label">
                    z1 (mm)
                  </label>
                  <input
                    type="text"
                    id="Z1mm"
                    name="Z1mm"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "Z1mm")}
                    className="form-control"
                  />
                </div>
                <div className="col mb-2 tabform-label">
                  <label htmlFor="Reinforcement" className="form-label">
                    Reinforcement
                  </label>
                  <input
                    type="text"
                    id="Reinforcement"
                    name="Reinforcement"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "Reinforcement")}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mb-2 tabform-label">
                  <label
                    htmlFor="Z2mm"
                    className="form-label"
                  >
                    z2 (mm)
                  </label>
                  <input type="text" className="form-control" 
                   id="Z2mm"
                   name="Z2mm"
                   onChange={handleConfigurationChange}
                   value={_.get(configurationDetails, "Z2mm")}
                   />
                </div>
                <div className="col mb-2 tabform-label">
                  <label htmlFor="Bonding" className="form-label">
                    Bonding
                  </label>
                  <input
                    type="text"
                    id="Bonding"
                    name="Bonding"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "Bonding")}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mb-2 tabform-label">
                  <label
                    htmlFor="RebatesPenetrationsmm3"
                    className="form-label"
                  >
                    Rebates &amp; Penetration (mm3)
                  </label>
                  <input
                    type="text"
                    id="RebatesPenetrationsmm3"
                    name="RebatesPenetrationsmm3"
                    onChange={handleConfigurationChange}
                    value={_.get(
                      configurationDetails,
                      "RebatesPenetrationsmm3"
                    )}
                    className="form-control"
                  />
                </div>
                <div className="col mb-2 tabform-label">
                  <label htmlFor="Inserts" className="form-label">
                    Inserts
                  </label>
                  <input
                    type="text"
                    id="Inserts"
                    name="Inserts"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "Inserts")}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="form-heading pt-3 pb-3">
                <p>Design Partner Information Request</p>
              </div>
              <div className="row">
                <div className="col mb-2 tabform-label">
                  <label htmlFor="SecurityRating" className="form-label">
                    Security Rating
                  </label>
                  <input
                    type="text"
                    id="SecurityRating"
                    name="SecurityRating"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "SecurityRating")}
                    className="form-control"
                  />
                </div>
                <div className="col mb-2 tabform-label">
                  <label htmlFor="ReferenceDrawing" className="form-label">
                    Reference Drawing
                  </label>
                  <input
                    type="text"
                    id="ReferenceDrawing"
                    name="ReferenceDrawing"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "ReferenceDrawing")}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mb-2 tabform-label">
                  <label htmlFor="FireRating" className="form-label">
                    Fire Rating
                  </label>
                  <input
                    type="text"
                    id="FireRating"
                    name="FireRating"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "FireRating")}
                    className="form-control"
                  />
                </div>
                <div className="col mb-2 tabform-label">
                  <label htmlFor="DesignStandards" className="form-label">
                    Design Standards
                  </label>
                  <select
                    id="DesignStandards"
                    className="form-select"
                    name="DesignStandards"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "DesignStandards")}
                  >
                    <option selected>--None--</option>
                    {loadDesignStandardsLists()}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col mb-2 tabform-label">
                  <label htmlFor="BlastRating" className="form-label">
                    Blast Rating
                  </label>
                  <input
                    type="text"
                    id="BlastRating"
                    name="BlastRating"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "BlastRating")}
                    className="form-control"
                  />
                </div>
                <div className="col mb-2 tabform-label">
                  <label
                    htmlFor="EngineeringTestingCertification"
                    className="form-label"
                  >
                    Engineering Testing Certification
                  </label>
                  <select
                    id="EngineeringTestingCertification"
                    name="EngineeringTestingCertification"
                    className="form-select"
                    onChange={handleConfigurationChange}
                    value={_.get(
                      configurationDetails,
                      "EngineeringTestingCertification"
                    )}
                  >
                    <option selected>--None--</option>
                    {loadEngTestCertLists()}
                  </select>
                </div>
              </div>
              {/*  */}
              <div className="form-heading pt-3 pb-3">
                <p>Trade Partner Information Request</p>
              </div>
              <div className="row">
                <div className="col mb-2 tabform-label">
                  <label htmlFor="HandoverInformation" className="form-label">
                    Handover information
                  </label>
                  <input
                    type="text"
                    id="HandoverInformation"
                    name="HandoverInformation"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "HandoverInformation")}
                    className="form-control"
                  />
                </div>
                <div className="col mb-2 tabform-label">
                  <label htmlFor="liftingpoints" className="form-label">
                    Lifting Points
                  </label>
                  <select
                    id="LiftingPoints"
                    name="LiftingPoints"
                    className="form-select"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "LiftingPoints")}
                  >
                    <option selected>--None--</option>
                    {loadLiftingPointLists()}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col mb-2 tabform-label">
                  <label htmlFor="MaterialHandling" className="form-label">
                    Material Handing
                  </label>
                  <input
                    type="text"
                    id="MaterialHandling"
                    name="MaterialHandling"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "MaterialHandling")}
                    className="form-control"
                  />
                </div>
                <div className="col mb-2 tabform-label">
                  <label htmlFor="ConnectionType" className="form-label">
                    Connection Type
                  </label>
                  <select
                    id="ConnectionType"
                    name="ConnectionType"
                    className="form-select"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "ConnectionType")}
                  >
                    <option selected>--None--</option>
                    {loadConnectionTypesLists()}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col mb-2 tabform-label">
                  <label
                    htmlFor="InstallationDeliveryChecks"
                    className="form-label"
                  >
                    Installation and Delivery Checks
                  </label>
                  <select
                    id="InstallationDeliveryChecks"
                    name="InstallationDeliveryChecks"
                    className="form-select"
                    onChange={handleConfigurationChange}
                    value={_.get(
                      configurationDetails,
                      "InstallationDeliveryChecks"
                    )}
                  >
                    <option selected>--None--</option>
                    {loadInstallationDeliveryChecksLists()}
                  </select>
                </div>
                <div className="col mb-2 tabform-label">
                  <div className="form-check">
                    <br></br>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="MSDS"
                      name="MSDS"
                      onChange={handleConfigurationChange}
                      checked={getWithDefault(
                        compData,
                        "content.ComponentConfigurationDetails.MSDS"
                      )}
                    />
                    <label className="form-check-label" htmlFor="MSDS">
                      MSDS
                    </label>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="form-heading pt-3 pb-3">
                <p>Information</p>
              </div>
              <div className="row">
                <div className="col mb-2 tabform-label">
                  <label htmlFor="CrossSectionalAream2" className="form-label">
                    Cross Sectional Area (m2)
                  </label>
                  <input
                    type="text"
                    id="CrossSectionalAream2"
                    name="CrossSectionalAream2"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "CrossSectionalAream2")}
                    className="form-control"
                  />
                </div>
                <div className="col mb-2 tabform-label">
                  <label htmlFor="ApproximateMasskg" className="form-label">
                    Approximate Mass (kg)
                  </label>
                  <input
                    type="text"
                    id="ApproximateMasskg"
                    name="ApproximateMasskg"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "ApproximateMasskg")}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mb-2 tabform-label">
                  <label htmlFor="Diametermm" className="form-label">
                    Diameter (mm)
                  </label>
                  <input
                    type="text"
                    id="Diametermm"
                    name="Diametermm"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "Diametermm")}
                    className="form-control"
                  />
                </div>
                <div className="col mb-2 tabform-label">
                  <label htmlFor="Landing1LengthM" className="form-label">
                    Landing 1 Length (M)
                  </label>
                  <input
                    type="text"
                    id="Landing1LengthM"
                    name="Landing1LengthM"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "Landing1LengthM")}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mb-2 tabform-label">
                  <label htmlFor="Perimeterm2" className="form-label">
                    Perimeter (m2)
                  </label>
                  <input
                    type="text"
                    id="Perimeterm2"
                    name="Perimeterm2"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "Perimeterm2")}
                    className="form-control"
                  />
                </div>
                <div className="col mb-2 tabform-label">
                  <label htmlFor="Landing2LengthM" className="form-label">
                    Landing 2 Length (M)
                  </label>
                  <input
                    type="text"
                    id="Landing2LengthM"
                    name="Perimeterm2"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "Perimeterm2")}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mb-2 tabform-label">
                  <label htmlFor="SurfaceAream2" className="form-label">
                    Surface Area (m2)
                  </label>
                  <input
                    type="text"
                    id="SurfaceAream2"
                    name="SurfaceAream2"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "SurfaceAream2")}
                    className="form-control"
                  />
                </div>
                <div className="col mb-2 tabform-label">
                  <label htmlFor="StairWidthmm" className="form-label">
                    Stair Width (mm)
                  </label>
                  <input
                    type="text"
                    id="StairWidthmm"
                    name="StairWidthmm"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "StairWidthmm")}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mb-2 tabform-label">
                  <label htmlFor="Thicknessmm" className="form-label">
                    Thickness (mm)
                  </label>
                  <input
                    type="text"
                    id="Thicknessmm"
                    name="Thicknessmm"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "Thicknessmm")}
                    className="form-control"
                  />
                </div>
                <div className="col mb-2 tabform-label">
                  <label htmlFor="TreadDepthmm" className="form-label">
                    Tread Depth (mm)
                  </label>
                  <input
                    type="text"
                    id="TreadDepthmm"
                    name="TreadDepthmm"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "TreadDepthmm")}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mb-2 tabform-label">
                  <label htmlFor="Uniclass" className="form-label">
                    Uniclass (mm)
                  </label>
                  <input
                    type="text"
                    id="Uniclass"
                    name="Uniclass"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "Uniclass")}
                    className="form-control"
                  />
                </div>
                <div className="col mb-2 tabform-label">
                  <label htmlFor="TreadRisemm" className="form-label">
                    Tread Rise (mm)
                  </label>
                  <input
                    type="text"
                    id="TreadRisemm"
                    name="TreadRisemm"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "TreadRisemm")}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mb-2 tabform-label">
                  <label htmlFor="Widthmm" className="form-label">
                    Width (mm)
                  </label>
                  <input
                    type="text"
                    id="Widthmm"
                    name="Widthmm"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "Widthmm")}
                    className="form-control"
                  />
                </div>
                <div className="col mb-2 tabform-label">
                  <label htmlFor="Lengthmm" className="form-label">
                    Length (mm)
                  </label>
                  <input
                    type="text"
                    id="Lengthmm"
                    name="Lengthmm"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "Lengthmm")}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mb-2 tabform-label">
                  <label htmlFor="Heightmm" className="form-label">
                    Height (mm)
                  </label>
                  <input
                    type="text"
                    id="Heightmm"
                    name="Heightmm"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "Heightmm")}
                    className="form-control"
                  />
                </div>
                <div className="col mb-2 tabform-label">
                  <label htmlFor="Weightkg" className="form-label">
                    Weight (kg)
                  </label>
                  <input
                    type="text"
                    id="Weightkg"
                    name="Weightkg"
                    onChange={handleConfigurationChange}
                    value={_.get(configurationDetails, "Weightkg")}
                    className="form-control"
                  />
                </div>
              </div>
            </form>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditFormModal;
