import React, { useState } from "react";
import "./Login.scss";
import Linkedin from "../../assets/linkedin.svg";
import Microsoft from "../../assets/microsoft.svg";
import Github from "../../assets/github.svg";
import Google from "../../assets/google.svg";
import { Link, useNavigate } from "react-router-dom";
import BOSlogo from "../../assets/2BOS-Logo.png";
import BOSPoweredby from "../../assets/2BOS-Poweredby.png";
import BOSlogofinal from "../../assets/2BOS-Final.logo.png";
import Logo from "../../assets/2BOS-logoset.png";
import { setInLocalStorage } from "../../utils/utils";
import { URL1 } from "../../constant/constant";
import logo from "../../assets/img/logo-white.svg";
import powered_logo from "../../assets/img/powered.svg";
import login_banner from "../../assets/img/login_banner.jpg";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import Visibility from "@mui/icons-material/Visibility";
import PersonOutline from "@mui/icons-material/PersonOutline";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  FormGroup,
  FormControl,
  InputAdornment,
  InputLabel,
  Button,
  Checkbox,
} from "@mui/material";

import { authenticate } from "./authenticate";
import userpool from "./userpool";

function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [isError, setIsError] = useState("");
  const [openDialog, setOpenDialog] = React.useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [loginErr, setLoginErr] = useState("");

  const formInputChange = (formField, value) => {
    if (formField === "email") {
      setEmail(value);
    }
    if (formField === "password") {
      setPassword(value);
    }
  };

  const validation = () => {
    return new Promise((resolve, reject) => {
      if (email === "" && password === "") {
        setEmailErr("Email is Required");
        setPasswordErr("Password is required");
        resolve({
          email: "Email is Required",
          password: "Password is required",
        });
      } else if (email === "") {
        setEmailErr("Email is Required");
        resolve({ email: "Email is Required", password: "" });
      } else if (password === "") {
        setPasswordErr("Password is required");
        resolve({ email: "", password: "Password is required" });
      } else if (password.length < 6) {
        setPasswordErr("must be 6 character");
        resolve({ email: "", password: "must be 6 character" });
      } else {
        resolve({ email: "", password: "" });
      }
    });
  };

  const handleServicePortalLogin = () => {
    setInLocalStorage("user_type", "service_user");
    setEmailErr("");
    setPasswordErr("");
    validation()
     .then(
        (res) => {
          if (res.email === "" && res.password === "") {
            setInLocalStorage("email", email);

            (async () => {
              const rawResponse = await fetch(
                `https://backend.sandbox-01.2bos.ai/api/qlik-login?email=` + email + "&password=" + password)       
        
              const content = await rawResponse.json();

              console.log('content', content);
         
              if(content && content.AuthenticationResult && content.AuthenticationResult.AccessToken) {
                  navigate("/home");
                } else if (content.message) {
                  setPasswordErr(content.message);
                }
              }
     
            )();  

            // authenticate(email, password)
            //   .then(
            //     (data) => {
            //       setInLocalStorage("email", email);
            //       setLoginErr("");
            //       navigate("/home");
            //     },
            //     (err) => {
            //       console.log(err);
            //       setLoginErr(err.message);
            //       // Navigate('/dashboard');
            //     }
            //   )
            //   .catch((err) => console.log(err));
           }
        },
        (err) => console.log(err)
      )
    .catch((err) => console.log(err));

  
    // return;
    // window.location.href =
    // "https://auth2bos.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize?client_id=76v72ogcm407239o57odda8grf&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=https://frontend.sandbox-01.2bos.ai/home";
  };
  const handleOtherUserLogin = () => {
    setInLocalStorage("user_type", "other_user");
    window.location.href = URL1;
    // window.location.href =
    //   "https://auth2bos.auth.ap-southeast-2.amazoncognito.com/authorize?response_type=token&identity_provider=2BOS-SSO&client_id=76v72ogcm407239o57odda8grf&redirect_uri=https://frontend.sandbox-01.2bos.ai/home";
    // window.location.href =
    //   "https://auth2bos.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize?client_id=76v72ogcm407239o57odda8grf&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=https://frontend.sandbox-01.2bos.ai/home";
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-cover-img bg-cover bg-center">
      <div className="flex flex-row justify-center align-middle min-h-[300px] max-w-[700px] w-full">
        <div className="w-4/12 flex justify-start">
          <img src={logo} alt="logo" className="block max-w-[200px]" />
        </div>
        <div className="w-8/12 flex justify-end">
          <div className="w-[350px] bg-white rounded-2xl p-6">
            <Box
              className="d-flex flex-column flex-start login_form"
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Typography sx={{ width: "100%" }} className="mb-3">
                <h2 className="text-center text-3xl font-bold">
                  Log into your account
                </h2>
                {/* <p>Welcome back! Please enter your details.</p> */}
              </Typography>
              <FormControl
                variant="outlined"
                fullwidth
                sx={{ width: "100%" }}
                className="mb-3"
                placeholder="Enter your email"
                onChange={(e) => formInputChange("email", e.target.value)}
                value={email}
                error={emailErr || loginErr}
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Email
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton>
                        <PersonOutline />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  sx={{ paddingRight: 0 }}
                />
                <FormHelperText style={{ marginLeft: 0 }}>
                  {emailErr}
                </FormHelperText>
              </FormControl>
              <FormControl
                className="mb-2"
                fullwidth
                variant="outlined"
                sx={{ width: "100%" }}
                value={password}
                onChange={(e) => {
                  formInputChange("password", e.target.value);
                }}
                error={passwordErr || loginErr}
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  sx={{ paddingRight: "12px" }}
                />
                <FormHelperText style={{ marginLeft: 0 }}>
                  {passwordErr}
                </FormHelperText>
                <FormHelperText style={{ marginLeft: 0 }}>
                  {loginErr}
                </FormHelperText>
                <Link
                  href="#"
                  variant="body2"
                  className="text-capitalize text-primary text-sm pb-2"
                >
                  <small>Forgot your password</small>
                </Link>
              </FormControl>
              <FormGroup
                className="d-flex flex-row align-items-center justify-content-between mb-3"
                sx={{ width: "100%" }}
              >
                <FormControlLabel
                  control={<Checkbox defaultChecked size="small" />}
                  label="Remember for 30 days"
                  className="text-sm"
                  sx={{
                    ".MuiFormControlLabel-label": { fontSize: 14 },
                  }}
                />
              </FormGroup>
              <Button
                onClick={handleServicePortalLogin}
                variant="contained"
                sx={{ width: "100%" }}
                className="mb-2"
              >
                Log in
              </Button>
              {/* <Typography>
                <small className="text-muted text-capitalize font-weight-normal me-1">
                  Don’t have an account?
                </small>
                <Link href="#" variant="body2" className="text-capitalize">
                  Sign up
                </Link>
              </Typography> */}
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
