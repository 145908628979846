import { createTheme } from "@mui/material/styles";
import { colors } from "./common";

// A custom theme for this app
const theme = createTheme({
  palette: {
    background: {
      default: colors.grey25,
    },
    primary: {
      main: colors.primary500,
      light: "#D9EBF3",
      dark: "#005D84",
      contrastText: "#fff",
    },
    secondary: {
      main: colors.grey100,
      light: "#ff7961",
      dark: "#7400c7",
      contrastText: "#fff",
    },
    error: {
      main: colors.error500,
    },
    text: {
      primary: colors.grey800,
    },
  },
  typography: {
    fontFamily: "Inter, system-ui, Avenir, Helvetica, Arial, sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // Change this value to set your desired border radius
          textTransform: "none",
        },
        sizeMedium: {
          padding: "8px 18px",
          borderRadius: "8px",
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          height: 40,
          fontWeight: "400",
          textTransform: "capitalize",
          fontSize: "14px",
          minWidth: "auto",
          boxShadow: "none",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "#AFADCC",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
});

export default theme;
