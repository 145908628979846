// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/2BOS-underconstructionbg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otherhome-bg {
  /* padding-top: 35px; */
  padding-top: 100px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.otherhome-heading-area {
  position: relative;
}
.otherheading-area-inside {
  position: absolute;
  bottom: 200px;
  width: 100%;
  text-align: center;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/OtherUserHome.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,yDAAkE;EAClE,iBAAiB;EACjB,4BAA4B;EAC5B,sBAAsB;EACtB,4BAA4B;EAC5B,2BAA2B;AAC7B;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,kBAAkB;EAClB,WAAW;AACb","sourcesContent":[".otherhome-bg {\n  /* padding-top: 35px; */\n  padding-top: 100px;\n  background-image: url(\"../../assets/2BOS-underconstructionbg.png\");\n  min-height: 100vh;\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-attachment: fixed;\n  background-position: center;\n}\n.otherhome-heading-area {\n  position: relative;\n}\n.otherheading-area-inside {\n  position: absolute;\n  bottom: 200px;\n  width: 100%;\n  text-align: center;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
