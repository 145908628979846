import React from 'react';
import "../../ScheduleDuration/Analyse/Analyse.css";
import Header from '../../ScheduleDuration/Header/Header';
import ScheduleSidebar from '../../ScheduleDuration/ScheduleSidebar/ScheduleSidebar';
import SubHeader from '../SubHeader/SubHeader';

function Analyse() {
    return (
        // <div>Analyse</div>
        <div className='container-fluid'>
            <div className='row'>
                {/* <div className='col-12 p-0'>
                    <Header />
                </div> */}
                {/* <div className='col-1 p-0 sidebar-width'>
                    <ScheduleSidebar />
                </div> */}
                <div className='col-11 p-0 durationreview_areaa'>
                    {/* <div className='subheader-place'>
                        <SubHeader />
                    </div> */}
                    {/* <div>
                        <div>Analyse</div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Analyse