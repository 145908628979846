import React from "react";
import "./Intelligence.css";
import BarChart3 from "../BarChart/BarChart3";
import BarChart4 from "../BarChart/BarChart4";

function Intelligence() {
  return (
    <div className="pie-chart-wrapper pt-4">
      <div className="row">
        <div className="col-md-12 col-lg-6 intellignece-barchart">
          <h6>Work volume by status</h6>
          <div className="first-chart">
            <BarChart3 />
          </div>
        </div>
        <div className="col-md-12 col-lg-6 intellignece-barchart">
          <h6>Throughput in last week by work type</h6>
          <div className="first-chart">
            <BarChart4 />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intelligence;
