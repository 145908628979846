import React from 'react';
import "../../ScheduleDuration/Quantify/Quantify.css";
import Header from '../../ScheduleDuration/Header/Header';
import ScheduleSidebar from '../../ScheduleDuration/ScheduleSidebar/ScheduleSidebar';
import SubHeader from '../SubHeader/SubHeader';
// import data from '../../../utils/data.json'; 
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';

function Quantify() {
  const [pageDetails, setPageDetails] = useState(null)
  const PAGE_DETAILS_API = "../data.json"
  // const fetchData = async () => {
  //   await axios.get(PAGE_DETAILS_API)
  //     .then(res => {
  //       setPageDetails(res.data.uiResources);
  //       console.log(res.data.uiResources)

  //     }).catch((err) => console.log(err))

  useEffect(() => {
    axios.get(PAGE_DETAILS_API)
      .then(res => {
        console.log(res.data.uiResources
        )
        setPageDetails(res.data.uiResources)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  // useEffect(() => {
  //   fetchData()

  // }, [])

  // useEffect(() => {

  //   console.log(pageDetails)
  // }, [pageDetails])

  return (
    // <div>Quantify</div>
    <div className='container-fluid'>
      <div className='row'>
        {/* <div className='col-12 p-0'>
          <Header />
        </div> */}
        {/* <div className='col-1 p-0 sidebar-width'>
          <ScheduleSidebar />
        </div> */}
        <div className='col-11 p-0 durationreview_areaa'>
          {/* <div className='subheader-place'>
            <SubHeader />
          </div> */}
          <div>
            {/* <div className='quantify-area-box pt-3 p-4'>
              <div className='resource-table'>
                <>
                  <div className='resource-table-heading'>
                    {pageDetails && pageDetails["resources"]["views"]["DCL"].map(pageDetails => (<>
                      <p>{pageDetails.name}</p>
                      <table className="table table-bordered tables-heading">
                        <thead>
                          <tr>
                            {pageDetails.config["presets"][0].children[0].children.map((items) => <th scope="col">{items["config"]["label"].slice(3, 20)}</th>)}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">1</th>
                            <td>Mark</td>
                            <td>Otto</td>
                            <td>@mdo</td>
                          </tr>
                          <tr>
                            <th scope="row">2</th>
                            <td>Jacob</td>
                            <td>Thornton</td>
                            <td>@fat</td>
                          </tr>
                        </tbody>
                      </table>
                    </>))}
                  </div>

                </>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Quantify