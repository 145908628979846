import { RouterProvider } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import routes from "./routes";
import "./App.scss";
import theme from "./theme";
import { Provider } from "react-redux";
import store from "./store";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={routes}></RouterProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
