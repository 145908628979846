export function isoToLocalDateFormat(isoString) {
  const date = new Date(isoString);
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  return new Intl.DateTimeFormat("en-GB", options).format(date);
}

export function parseQueryString(url) {
  const params = new URLSearchParams(new URLSearchParams(url).toString());
  return Object.fromEntries(params);
}

export function getCurrentUrl() {
  return window.location.href;
}
export function setInSessionStorage(key, value) {
  sessionStorage.setItem(key, value);
}
export function getFromSessionStorage(key) {
  const item = sessionStorage.getItem(key);
  return item === null ? null : item;
}
export function updateInSessionStorage(key, newValue) {
  let currentData = getFromSessionStorage(key);
  if (currentData && typeof newValue === "string") {
    setInSessionStorage(key, newValue);
  }
}
export function hasAndIsDefinedInSessionStorage(key) {
  const value = getFromSessionStorage(key);
  return value !== null && value !== undefined && value !== "";
}
export function deleteFromSessionStorage(key) {
  sessionStorage.removeItem(key);
}
export function setInLocalStorage(key, value) {
  localStorage.setItem(key, value);
}
export function getFromLocalStorage(key) {
  const item = localStorage.getItem(key);
  return item === null ? null : item;
}
export function deleteFromLocalStorage(key) {
  localStorage.removeItem(key);
}
export function hasAndIsDefinedInLocalStorage(key) {
  const value = getFromLocalStorage(key);
  return value !== null && value !== undefined && value !== "";
}

export function isOtherUser() {
  const user = getFromLocalStorage("user_type");
  return user === "other_user";
}
export function isServiceUser() {
  const user = getFromLocalStorage("user_type");
  return user === "service_user";
}

export function parseTokenFromUrl(url) {
  url = url.replace(/#/, "?");
  const queryString = url.slice(url.indexOf("?") + 1).split("&");
  const tokenData = {};

  queryString.forEach((param) => {
    const equals = param.indexOf("=");
    const key = equals > -1 ? param.substr(0, equals) : param;

    switch (key) {
      case "access_token":
        tokenData.accessToken = param.substr(equals + 1);
        break;
      case "id_token":
        tokenData.idToken = param.substr(equals + 1);
        break;
      case "token_type":
        tokenData.tokenType = param.substr(equals + 1);
        break;
      case "expires_in":
        tokenData.expiresIn = parseInt(param.substr(equals + 1));
        break;
      default:
      // Do nothing
    }
  });
  return tokenData;
}
export function clearLocalStorage() {
  localStorage.clear();
}
export function clearSessionStorage() {
  sessionStorage.clear();
}
export function clearBothStorages() {
  clearLocalStorage();
  clearSessionStorage();
}
export function removeChatbotScripts() {
  const scriptToRemove = document.getElementById("lex-web-ui-loader-script");
  const contentScriptToRemove = document.getElementById(
    "lex-web-ui-loader-content-script"
  );
  if (scriptToRemove) scriptToRemove.remove();
  if (contentScriptToRemove) contentScriptToRemove.remove();
}
