// import { PageHubPropsTypes } from "./types";
import { useMemo, useState } from "react";
import Panel from "./Panel";
import {
  Grid,
  Typography,
  Button,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Tab,
  Tabs,
  Checkbox,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";

import tableData from "./tableData.json";
import moment from "moment/moment";
import TableImg from "../../assets/workspace-table.png";

const drawerWidth = 400;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
    /**
     * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
     * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
     * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
     * proper interaction with the underlying content.
     */
    position: "relative",
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
  minHeight: "0 !important",
}));

// export default function Hub(props: PageHubPropsTypes){
export default function Hub() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [colFilters, setColFilters] = useState("");
  const [value, setValue] = useState(0);
  const [sorting, setSorting] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // {
  //   "id": "id1",
  //   "title": "title 1",
  //   "ext": "ext 1",
  //   "type": "type 1",
  //   "tags": "tags 1",
  //   "uploadedBy": "uploaded by",
  //   "uploadedDate": "uploaded date",
  //   "lastModified": " modified date",
  //   "fileNo": "file No",
  //   "menu": "menu"
  // }

  const data = useMemo(() => tableData, []);

  /** @type import('@tanstack/react-table').ColumnDef<any> */
  const columns = [
    {
      accessorKey: "isSelected",
      header: () => <Checkbox checked={true} />,
      // accessorFn: (row) => <Checkbox checked={row.isSelected} />,
      cell: (info) => <Checkbox checked={!!info.getValue()} />,
    },
    {
      header: "Title",
      accessorKey: "title",
      filterFn: "includesString",
    },
    {
      header: "Ext",
      accessorKey: "ext",
    },
    {
      header: "Type",
      accessorKey: "type",
    },
    {
      header: "Tags",
      accessorKey: "tags",
    },
    {
      header: "Uploaded By",
      accessorKey: "uploadedBy",
    },
    {
      header: "Date Uploaded",
      accessorKey: "uploadedDate",
    },
    {
      header: "Last Modified",
      accessorKey: "lastModified",
      cell: (info) => moment(info.getValue()).format("ll"),
    },
    {
      header: "File Number",
      accessorKey: "fileNo",
    },
    {
      header: "Menu",
      accessorKey: "menu",
    },
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),

    // onColumnFiltersChange: setColFilters,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sorting,
      globalFilter: colFilters,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setColFilters,
  });

  console.log(table.getState().columnFilters);
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Box sx={{ display: "flex" }}>
        {/* <CssBaseline /> */}
        <Grid container sx={{ padding: "24px 0" }}>
          <Grid item xs={8}>
            <Typography
              variant="h1"
              sx={{
                fontSize: "24px",
                fontWeight: 600,
                color: "#171533",
                pb: 1,
              }}
            >
              Hub
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "15px",
                fontWeight: 400,
                color: "#4E4E61",
                lineHeight: 1.4,
              }}
            >
              Manage resources, requests and more
            </Typography>
          </Grid>
          <Grid item xs={4} textAlign={"right"}>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "#FFF",
                padding: "4px 7px",
                border: "2px solid #784EF5",
                "&:hover": {
                  backgroundColor: "#FFF",
                  border: "2px solid #784EF5",
                },
              }}
              open={open}
              onClick={handleDrawerOpen}
            >
              Add <KeyboardArrowDownIcon />
            </Button>
          </Grid>

          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
              },
            }}
            variant="persistent"
            anchor="right"
            open={open}
          >
            <DrawerHeader>
              <IconButton
                onClick={handleDrawerClose}
                sx={{
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                  zIndex: 2000,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DrawerHeader>
            <Panel />
          </Drawer>
        </Grid>
      </Box>
      <img src={TableImg} className="w-full max-w-7xl" alt="Workspace Table" />
      {/* <Box
        component="article"
        sx={{
          borderRadius: 3,
          border: "1px solid #D7D5E5",
          overflow: "hidden",
          backgroundColor: "#fff",
        }}
      >
        <Grid container spacing={6} justifyContent="center" alignItems="center">
          <Grid item xs={6}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="documents and links"
              sx={{
                ".MuiTabs-indicator": {
                  backgroundColor: "transparent",
                },
                ".MuiButtonBase-root.MuiTab-root": {
                  textTransform: "none",
                },
              }}
            >
              <Tab
                label="Document"
                {...a11yProps(0)}
                sx={{
                  height: "30px",
                  padding: "2px",
                  margin: "2px 4px",
                  flexBasis: "50%",
                  maxWidth: "none",
                  borderRadius: "5px",
                  minHeight: 0,
                  "&.Mui-selected": {
                    backgroundColor: "#fff",
                    borderRadius: "4px",
                  },
                }}
                className="first-tab"
              />
              <Tab
                label="Links"
                {...a11yProps(1)}
                sx={{
                  height: "30px",
                  padding: "2px",
                  margin: "2px 4px",
                  flexBasis: "50%",
                  maxWidth: "none",
                  minHeight: "0",
                  "&.Mui-selected": {
                    backgroundColor: "#fff",
                    borderRadius: "4px",
                  },
                }}
                className="second-tab"
              />
            </Tabs>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            Filter
            <input
              type="text"
              value={colFilters}
              onChange={(e) => setColFilters(e.target.value)}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableHead key={headerGroup.id}>
                <TableRow>
                  {headerGroup.headers.map((header) => (
                    <TableCell
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {
                        { asc: "up", desc: "down" }[
                          header.column.getIsSorted() ?? null
                        ]
                      }
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            ))}

            <TableBody>
              {table.getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box> */}
    </>
  );
}
