import React from "react";
import { Navigate } from "react-router-dom";
import { hasAndIsDefinedInSessionStorage } from "../../utils/utils";

const withAuth = (WrappedComponent) => {
  return ({ children }) => {
    // const condition =
    //   hasAndIsDefinedInSessionStorage("accessToken") &&
    //   hasAndIsDefinedInSessionStorage("idToken");

    // if (!condition) {
    //   return <Navigate to="/" replace />;
    // }

    return <WrappedComponent>{children}</WrappedComponent>;
  };
};

export default withAuth;
