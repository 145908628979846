import MuiButton from "@mui/material/Button";

const Button = ({ customProp, children, ...props }) => {
  return (
    <MuiButton {...props}>
      {customProp && <span>{customProp}</span>}
      {children}
    </MuiButton>
  );
};

export default Button;
