import React from 'react'

function Alice() {
  return (
    // <div>Alice</div> 
    // <iframe src="https://app.a16s.com/login" width="100%" height="650"></iframe>
    // <iframe width="100%" height="650" src="https://www.google.com/webhp?igu=1"></iframe>
    <iframe src="https://d-97674299db.awsapps.com/start/#/saml/default/Alice/ins-5fbf846db8629ef2" width="100%" height="650"></iframe>
  )
}

export default Alice