import React from 'react'
import "./CMBuilder.css";

function CMBuilder() {
  return (
    // <div>CMBuilder</div>
    <iframe src="https://app.cmbuilder.io/Laing%20ORourke" width="100%" height="650"></iframe>
    // <div className="cmbuilder-bg"></div>
  )
}

export default CMBuilder