import React, { createContext, useState } from 'react'
import { useGlobalFilter, useSortBy, useTable } from 'react-table';


export const AppContext = createContext();
function Appstateprovider(props) {
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);

    const {
        rows,
        state,
        prepareRow,
      } = useTable(
        {
          columns,
          data,
        },
        useGlobalFilter,
        useSortBy,
      );
    
      const {globalFilter, setGlobalFilter } = state;

  return (
    // <div>Appstateprovider</div>

    <AppContext.Provider
      value={{
        columns,setColumns,data,setData,globalFilter, setGlobalFilter
        
      }}
    >
      {props.children}
     
    </AppContext.Provider>
  )
}

export default Appstateprovider