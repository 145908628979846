import { Outlet } from "react-router-dom";

import { Container, Main, Wrapper } from "./styles";
import { useSelector } from "react-redux";

import Header from "../Header";
import NavMain from "../nav/NavMain";
import { useEffect } from "react";

// export default function Layout(props: PageLayoutPropsTypes){
export default function Layout({ children }) {
  const open = useSelector((state) => state.navDrawer.enableNavDrawer);

  useEffect(() => {
    const scriptToRemove = document.getElementById("lex-web-ui-loader-script");
    const contentScriptToRemove = document.getElementById(
      "lex-web-ui-loader-content-script"
    );
    if (scriptToRemove && contentScriptToRemove) {
    } else {
      const script = document.createElement("script");
      script.src =
        "https://d2ri7jgo7n7mn7.cloudfront.net/lex-web-ui-loader.min.js";
      script.id = "lex-web-ui-loader-script";

      script.onload = function () {
        const scriptContent = `
        var loaderOpts = {
          baseUrl: 'https://d2ri7jgo7n7mn7.cloudfront.net/',
          shouldLoadMinDeps: true
        };
        var loader = new ChatBotUiLoader.IframeLoader(loaderOpts);
        var chatbotUiConfig = {
          lex: {
            sessionAttributes: {
              userAgent: navigator.userAgent,
              QNAClientFilter: ''
            },
          },
        };
        loader.load(chatbotUiConfig)
      .catch(function (error) { console.error(error); });`;

        const scriptTag = document.createElement("script");
        scriptTag.innerHTML = scriptContent;
        scriptTag.id = "lex-web-ui-loader-content-script"; // assign an id to the script tag
        document.head.appendChild(scriptTag);
      };
      document.head.appendChild(script);
    }
  }, []);

  return (
    <Wrapper>
      <NavMain />

      <Main open={open} sx={{ height: "100vh" }}>
        <Header />
        <Container>
          {/* <Outlet /> */}
          {children}
        </Container>
      </Main>
    </Wrapper>
  );
}
