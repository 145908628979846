import React, { useEffect } from "react";
import { QlikEmbed, QlikEmbedConfig } from "@qlik/embed-react";

const HOST = process.env.REACT_APP_QLIK_HOST;
const CLIENT_ID = process.env.REACT_APP_QLIK_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_QLIK_CLIENT_SECRET;
const REDIRECT_URI = process.env.REACT_APP_QLIK_REDIRECT_URI;

const hostConfig = {
  host: HOST, //<QLIK_CLOUD_TENANT>
  clientId: CLIENT_ID,
  // clientSecret: CLIENT_SECRET,
  redirectUri: REDIRECT_URI,
  accessTokenStorage: "session",
  autoRedirect: true,
  authType: "Oauth2",
};

const QlikObject = ( { appId, sheetId} ) => (
  <QlikEmbedConfig.Provider value={hostConfig}>
    <div>
      <QlikEmbed ui="classic/app" appId={appId} sheetId={sheetId}/>
    </div>
  </QlikEmbedConfig.Provider>
);

export default QlikObject;
