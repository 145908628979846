import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function ApprovalModal({ show, onHide , setdata, handleSumbit}) {
  const [comment, setComment] = useState('')
  
  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Header closeButton style={{textAlign: "center"}}>
        <Modal.Title style={{textAlign: "center", width: "100%"}}><h5 style={{margin: "0"}}>Approve Component</h5></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="comment-area">
          <div className="mb-3">
            <label for="exampleFormControlTextarea1" className="form-label">
              Comments
            </label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="5"
              onChange={(e)=>{setdata(e.target.value)}}
            ></textarea>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSumbit}>Approve</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ApprovalModal;
