import * as React from "react";
import {
  FormControl,
  FormLabel,
  Chip,
  Autocomplete,
  TextField,
} from "@mui/material";
import TagIcon from "./tag-03.svg";

const tags = [
  "procurement",
  "overview",
  "program",
  "Schedule",
  "P7",
  "tag01",
  "tag02",
  "dry",
  "softlogic",
  "flow",
];

const AddTagsComponent = () => {
  return (
    <FormControl sx={{ m: 1, width: "100%" }}>
      <FormLabel>Add tags (optional)</FormLabel>
      <Autocomplete
        multiple
        id="add-tags"
        size="small"
        sx={{ width: "100%" }}
        options={tags}
        getOptionLabel={(option) => option}
        renderOption={(props, option) => (
          <li {...props} key={option}>
            <Chip
              label={option}
              variant="outlined"
              sx={{
                backgroundColor: "#ECFDF3",
                color: "#067647",
                border: "1px solid #ABEFC6",
                height: "30px",
              }}
            />
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label=""
            placeholder=""
            sx={{
              ".MuiButtonBase-root.MuiChip-root.MuiChip-filled": {
                backgroundColor: "#ECFDF3",
                color: "#067647",
                border: "1px solid #ABEFC6",
                height: "30px",
              },
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <img src={TagIcon} style={{ paddingRight: 10 }} />
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
};

export default AddTagsComponent;
