import React, { useState } from "react";
import "../Hardhat/Hardhat.css";
import HeadhatTable from "./HeadhatTable";
import HardhatHeader from "./HardhatHeader";
import { IoSearchOutline } from "react-icons/io5";
import withAuth from "../../components/HOC/withAuth";

export const GlobalFilter = ({ value, setValue }) => {
  return (
    <span>
      {" "}
      <input
        style={{
          width: "90%",
          borderRadius: "30px",
          borderColor: "#fff",
          padding: "5px 20px",
        }}
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        placeholder="Search Components..."
      />
      <div
        className="input-group-append component-search-area"
        style={{ marginTop: -1 }}
      >
        <button
          className="btn componentserach-btn"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 32,
          }}
        >
          <IoSearchOutline />
        </button>
      </div>
    </span>
  );
};

function Hardhat(props) {
  const [value, setValue] = useState("");

  const renderChildren = () => {
    return React.Children.map(props.children, (child) => {
      return React.cloneElement(child, {
        value: value,
      });
    });
  };
  return (
    <>
      <div>
        <HardhatHeader
          globalFilter={<GlobalFilter value={value} setValue={setValue} />}
        />
      </div>
      <div className="container-fluid">
        <div>
          {renderChildren()}
          {/* {children} */}
          {/* <HeadhatTable /> */}
        </div>
      </div>
    </>
  );
}

export default withAuth(Hardhat);
