// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/2BOS-underconstructionbg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projectsrl-bg {
  padding-top: 100px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.project-detail-table table::-webkit-scrollbar {
  width: 5px;
  overflow: hidden;
}

.project-srl-table thead tr th {
  min-width: 100px !important;
}

.project_srl_table td {
  font-size: 14px;
  white-space: nowrap;
  color: #211f3d;
}
.project_srl_table th {
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/pages/Project-SRL/Project_SRL.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,yDAAA;EACA,iBAAA;EACA,4BAAA;EACA,sBAAA;EACA,4BAAA;EACA,2BAAA;AACF;;AAEA;EACE,UAAA;EACA,gBAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAGE;EACE,eAAA;EACA,mBAAA;EACA,cAAA;AAAJ;AAGE;EACE,mBAAA;AADJ","sourcesContent":[".projectsrl-bg {\n  padding-top: 100px;\n  background-image: url(\"../../assets/2BOS-underconstructionbg.png\");\n  min-height: 100vh;\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-attachment: fixed;\n  background-position: center;\n}\n\n.project-detail-table table::-webkit-scrollbar {\n  width: 5px;\n  overflow: hidden;\n}\n\n.project-srl-table thead tr th {\n  min-width: 100px !important;\n}\n\n.project_srl_table {\n  td {\n    font-size: 14px;\n    white-space: nowrap;\n    color: #211f3d;\n  }\n\n  th {\n    white-space: nowrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
