import React from "react";
import "../Header/Header.css";
import checkbox from "../../../assets/check_box.png";
import bell from "../../../assets/bell_boxx.png";
import chatbot from "../../../assets/chat_bot.png";
import UserIcon from "../../../assets/user-icon.png";

function Header() {
  return (
    <div className="row header-btm-line pt-2 pb-2">
      <div className="col-md-6 pl-5">
        <div className="project-name d-flex">
          {/* <p className='m-0'>Sea Rail <span>/ Schedule Duration Review Jan</span></p> */}
          <div className="dropdown">
            <button
              type="button"
              className="btn btn-toggle-bg dropdown-toggle d-flex p-0"
              data-bs-toggle="dropdown"
            >
              <p className="m-0">
                Sea Rail <span>/ Schedule Duration Review Jan</span>
              </p>
            </button>
            <ul className="dropdown-menu dropdown-menu-below">
              <li>
                <div className="dropdown-item dropdown-projectname">
                  <p>/ Schedule Duration Review Feb</p>
                </div>
              </li>
              <li>
                <div className="dropdown-item dropdown-projectname">
                  <p>/ Schedule Duration Review Mar</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="profile-icon">
            <div className="bell-iconn pl-5">
              <img src={UserIcon} alt="" height={25} />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-md-6">
        <div className="notify-area d-flex">
          <div className="check-icon icon-space">
            <img src={checkbox} alt="check_box_icon" />
          </div>
          <div className="bell-icon icon-space">
            <img src={bell} alt="bell_box_icon" />
          </div>
          <div className="chatbott-icon icon-space">
            <img src={chatbot} alt="chatbot_icon" />
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Header;
