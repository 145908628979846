import axios from "axios";
import { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

function BarChat() {
  const baseURL = "https://backend.sandbox-01.2bos.ai/api";
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const chartDataRes = await axios.get(`${baseURL}/record_type_data`);
      setChartData(chartDataRes.data.data);
    };
    fetchData();
  }, []);

  function convertData(data) {
    return chartData.map((item) => {
      return {
        ...item,
        ltm4akwpe0swyd4edw5: parseInt(item.ltm4akwpe0swyd4edw5, 10),
      };
    });
  }
  const tempdata = convertData(chartData);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload.length > 0) {
      const {
        payload: { ComponentType },
      } = payload[0];
      return (
        <div
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            border: "1px solid #ddd",
            borderRadius: "5px",
            padding: "2px",
            fontSize: "8px",
            lineHeight: "4px",
          }}
        >
          <p>{label}:</p>
          <p>Count: {payload[0].value}</p>
          <p>Component Type: {ComponentType}</p>
        </div>
      );
    }
    return null;
  };

  const CustomTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fontSize={8} fill="#666">
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart width={450} height={400} data={tempdata}>
        <XAxis dataKey="ComponentCategoryID" />
        <YAxis />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="ltm4akwpe0swyd4edw5" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default BarChat;
