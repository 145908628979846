import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { BiPencil } from "react-icons/bi";

const ComponentField = ({ label, value, formModalShow, status }) => {
  const procssedLabel = (label) => {
    if (label === "Component" || label === "Category") {
      return (
        <span>
          {label}
          <span style={{ color: "red" }}>&nbsp;*</span>
        </span>
      );
    } else {
      return <span>{label}</span>;
    }
  };
  return (
    <Form.Group>
      <Row>
        <Col xs={12}>
          <p className="mb-0" style={{ fontSize: "14px" }}>
            {procssedLabel(label)}
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={10}>
          <p className="mb-0" style={{ fontSize: "14px", fontWeight: "500" }}>
            {value}
          </p>
        </Col>
        <Col xs={2}>
          <Button
            className="m-0 p-0"
            variant=""
            style={{ border: "none" }}
            disabled={status === "Archived" || status === "Shared for Review"}
            onClick={formModalShow}
          >
            <BiPencil size={15} />
          </Button>
        </Col>
      </Row>
    </Form.Group>
  );
};

export default ComponentField;
