// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-toggle-bg {
  border: none !important;
}
.dropdown-menu-below {
  position: absolute !important;
  inset: 0px auto auto 0px !important;
  margin: 0px 55px !important;
  transform: translate3d(0px, 35.3333px, 0px) !important;
  /* padding: 29px 20px; */
  min-width: 210px !important;
}
.dropdown-toggle::after {
  margin-left: 1em !important;
  margin-top: 0.6em;
}
.dropdown-projectname p {
  font-size: 12px !important;
  margin: 0 !important;
  font-weight: 600 !important;
}
.header-btm-line {
  border-bottom: 1px solid #acacac !important;
}
.project-name {
  padding-left: 20px;
}
.project-name p span {
  font-weight: 600;
  font-size: 14px;
}
.project-name p {
  font-size: 14px;
  font-weight: 400;
}
.notify-area {
  justify-content: flex-end;
}
.icon-space {
  margin-top: -2px;
  padding: 0px 25px;
  /* margin-left: 20px; */
}
.icon-space img {
  height: 20px;
  /* margin: 5px; */
}
.bell-icon img {
  height: 18px;
}
.profile-icon {
  padding-left: 70px !important;
}
/* .icon-space:hover {
    background-color: #f1f1f1;
    color: #000;
    border-radius: 5px;
} */
`, "",{"version":3,"sources":["webpack://./src/pages/ScheduleDuration/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;AACA;EACE,6BAA6B;EAC7B,mCAAmC;EACnC,2BAA2B;EAC3B,sDAAsD;EACtD,wBAAwB;EACxB,2BAA2B;AAC7B;AACA;EACE,2BAA2B;EAC3B,iBAAiB;AACnB;AACA;EACE,0BAA0B;EAC1B,oBAAoB;EACpB,2BAA2B;AAC7B;AACA;EACE,2CAA2C;AAC7C;AACA;EACE,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;AACzB;AACA;EACE,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,YAAY;AACd;AACA;EACE,6BAA6B;AAC/B;AACA;;;;GAIG","sourcesContent":[".btn-toggle-bg {\n  border: none !important;\n}\n.dropdown-menu-below {\n  position: absolute !important;\n  inset: 0px auto auto 0px !important;\n  margin: 0px 55px !important;\n  transform: translate3d(0px, 35.3333px, 0px) !important;\n  /* padding: 29px 20px; */\n  min-width: 210px !important;\n}\n.dropdown-toggle::after {\n  margin-left: 1em !important;\n  margin-top: 0.6em;\n}\n.dropdown-projectname p {\n  font-size: 12px !important;\n  margin: 0 !important;\n  font-weight: 600 !important;\n}\n.header-btm-line {\n  border-bottom: 1px solid #acacac !important;\n}\n.project-name {\n  padding-left: 20px;\n}\n.project-name p span {\n  font-weight: 600;\n  font-size: 14px;\n}\n.project-name p {\n  font-size: 14px;\n  font-weight: 400;\n}\n.notify-area {\n  justify-content: flex-end;\n}\n.icon-space {\n  margin-top: -2px;\n  padding: 0px 25px;\n  /* margin-left: 20px; */\n}\n.icon-space img {\n  height: 20px;\n  /* margin: 5px; */\n}\n.bell-icon img {\n  height: 18px;\n}\n.profile-icon {\n  padding-left: 70px !important;\n}\n/* .icon-space:hover {\n    background-color: #f1f1f1;\n    color: #000;\n    border-radius: 5px;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
