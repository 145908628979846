// import { PageBoardsPropsTypes } from "./types";

import { Grid, Typography } from "@mui/material";

import banner from "../../assets/landing-page-banner.png";

// export default function Boards(props: PageBoardsPropsTypes){
export default function Boards() {
  return (
    <Grid container sx={{ padding: "24px 0" }} className="justify-center">
      <img src={banner} alt="Get ready for 2BOS" className="w-full max-w-7xl" />
    </Grid>
  );
}
