import { IconButton, InputBase, Paper } from "@mui/material";
import Icons from "../Icons";

export default function SearchField({ placeholder }) {
  return (
    <Paper
      component="form"
      elevation={1}
      variant="outlined"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 350,
        height: 40,
        borderColor: "#918FA8",
        borderRadius: "8px",
        fontSize: 14,
      }}
    >
      <IconButton sx={{ p: "5px" }} aria-label="menu" className="scale-90">
        <Icons name="search" />
      </IconButton>
      <InputBase
        sx={{ ml: 0.5, flex: 1, fontSize: 14 }}
        placeholder={placeholder}
        className="justify-center items-center"
      />
    </Paper>
  );
}
