// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Quantify Page CSS Start from here.... */
.quantify-area-box {
	margin-bottom: 400px !important;
}

.resource-table-heading p {
	color: #17af27;
	font-weight: 600;
}

.tables-heading {
	border-left: 5px solid #17af27 !important;
}

.tables-heading th {
	font-size: 10px;
	font-weight: 500;
}
.durationreview_areaa{
    background-color: #f6f6f6;
	width: 100% !important;
	/* height: 100vh; */
}`, "",{"version":3,"sources":["webpack://./src/pages/ScheduleDuration/Quantify/Quantify.css"],"names":[],"mappings":"AAAA,0CAA0C;AAC1C;CACC,+BAA+B;AAChC;;AAEA;CACC,cAAc;CACd,gBAAgB;AACjB;;AAEA;CACC,yCAAyC;AAC1C;;AAEA;CACC,eAAe;CACf,gBAAgB;AACjB;AACA;IACI,yBAAyB;CAC5B,sBAAsB;CACtB,mBAAmB;AACpB","sourcesContent":["/* Quantify Page CSS Start from here.... */\n.quantify-area-box {\n\tmargin-bottom: 400px !important;\n}\n\n.resource-table-heading p {\n\tcolor: #17af27;\n\tfont-weight: 600;\n}\n\n.tables-heading {\n\tborder-left: 5px solid #17af27 !important;\n}\n\n.tables-heading th {\n\tfont-size: 10px;\n\tfont-weight: 500;\n}\n.durationreview_areaa{\n    background-color: #f6f6f6;\n\twidth: 100% !important;\n\t/* height: 100vh; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
