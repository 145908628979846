// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-internal-page{
    background-color: #000;
    min-height: 100vh;
}
.sidebar-internal-page .menu-inside-box {
    list-style: none;
    padding: 35px 0px;
    /* padding-left: 0; */
}

.sidebar-internal-page .menu-inside-box li:hover{
    background-color: #343434;
}

.menu-inside-box li {
    /* padding-bottom: 5px; */
}

.sidebar-internal-page .active{
    background-color: #343434;
    display: block;
    font-weight: 600;
}

.sidebar-internal-page .menu-inside-box li a {
    text-decoration: none;
}

.sidebar-internal-page .menu-icon .active {
    border-left: none !important;
}

.sidebar-internal-page .menu-icon {
    text-align: center;
    padding: 5px 5px;
}

.sidebar-internal-page .menu-icon img {
    height: 20px;
}

.sidebar-internal-page .menu-icon p {
    color: #fff;
    padding-top: 6px;
    margin-bottom: 0;
    font-size: 9px;
}`, "",{"version":3,"sources":["webpack://./src/pages/ScheduleDuration/ScheduleSidebar/ScheduleSidebar.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".sidebar-internal-page{\n    background-color: #000;\n    min-height: 100vh;\n}\n.sidebar-internal-page .menu-inside-box {\n    list-style: none;\n    padding: 35px 0px;\n    /* padding-left: 0; */\n}\n\n.sidebar-internal-page .menu-inside-box li:hover{\n    background-color: #343434;\n}\n\n.menu-inside-box li {\n    /* padding-bottom: 5px; */\n}\n\n.sidebar-internal-page .active{\n    background-color: #343434;\n    display: block;\n    font-weight: 600;\n}\n\n.sidebar-internal-page .menu-inside-box li a {\n    text-decoration: none;\n}\n\n.sidebar-internal-page .menu-icon .active {\n    border-left: none !important;\n}\n\n.sidebar-internal-page .menu-icon {\n    text-align: center;\n    padding: 5px 5px;\n}\n\n.sidebar-internal-page .menu-icon img {\n    height: 20px;\n}\n\n.sidebar-internal-page .menu-icon p {\n    color: #fff;\n    padding-top: 6px;\n    margin-bottom: 0;\n    font-size: 9px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
