import React, { Component } from "react";
import { DropzoneArea } from "mui-file-dropzone";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UploadIcon from "./upload-cloud-02.svg";
import TrashIcon from "./trash-01.svg";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

class FileDropZOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
  }
  handleChange = (files) => {
    this.setState({
      files: files,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSave = (files) => {
    this.setState({
      files: files,
      open: false,
    });
  };

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  render() {
    const { files } = this.state;

    return (
      <>
        <FormControl
          sx={{
            m: 1,
            width: "100%",
          }}
        >
          <DropzoneArea
            dropzoneClass="upload-wrapper"
            dropzoneText={
              <Box
                component="div"
                className="upload-content"
                px={6}
                py={6}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                borderRadius={4}
                sx={{
                  paddingTop: "65px !important",
                  ".MuiBox-root": {
                    padding: "1px 7px",
                  },
                }}
              >
                <Box
                  mt={1}
                  sx={{
                    border: "1px solid #C4C3D9",
                    padding: "1px 6px",
                    borderRadius: "4px",
                  }}
                >
                  <img src={UploadIcon} />
                </Box>
                <Box mt={1}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#918FA8",
                      fontSize: "0.88rem",
                    }}
                  >
                    <span style={{ color: "#784EF5" }}>Click to upload</span> or
                    drag and drop <br /> XER, CSV, JPG or PDF (20mb max)
                  </Typography>
                </Box>
              </Box>
            }
            open={this.state.open}
            onSave={this.handleSave.bind(this)}
            acceptedFiles={["image/*", "application/pdf", "text/csv"]}
            maxFileSize={20971520}
            onChange={this.handleChange.bind(this)}
            showPreviewsInDropzone={false}
            showPreviews={true}
            showFileNames={false}
            showFileNamesInPreview={false}
            filesLimit={10}
            getPreviewIcon={(file) => {
              return (
                <Box
                  sx={{
                    backgroundColor: "#E7E6F2",
                    borderRadius: "4px",
                    padding: "7px !important",
                    textAlign: "left",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={9}
                      sx={{
                        textAlign: "left",
                        fontWeight: "700",
                        fontSize: "0.9rem",
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {file.file.path.split(".")[0]}
                    </Grid>
                    <Grid item xs={2}>
                      <Box
                        component="span"
                        sx={{
                          padding: "1px 8px !important",
                          borderRadius: "3px !important",
                          fontSize: "12px !important",
                        }}
                        className={
                          "file-tag " + file.data.split(";")[0].split("/")[1]
                        }
                      >
                        {file.data.split(";")[0].split("/")[1].toUpperCase()}
                      </Box>
                    </Grid>
                    <Grid item xs={1}>
                      &nbsp;
                    </Grid>
                  </Grid>
                </Box>
              );
            }}
            showAlerts={["error"]}
            sx={{
              borderStyle: "inset",
              borderWidth: "1px", 
              border: '1px solid #918FA8',
              " .MuiBox-root": {
                borderRadius: "4px",
                flexDirection: "column",
                alignItems: "center",
                gap: 0,
                paddingBottom: 0,
              },
            }}
          />
        </FormControl>
      </>
    );
  }
}

export default FileDropZOne;
