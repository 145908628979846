// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/2BOS-underconstructionbg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body_text {
  font-size: 1rem;
}

.orchestration-bg {
  /* padding-top: 35px; */
  padding-top: 100px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed; */
  background-position: center;
}

.my_table thead th {
  background-color: #d7d5e5;
  height: 44px;
  text-transform: capitalize;
  font-weight: 500;
}
.my_table thead td {
  padding: 1rem;
  font-size: 14px;
  color: #211f3d;
}
.my_table .table > :not(caption) > * > * {
  padding: 1rem;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/variable.scss","webpack://./src/pages/Orchestration/Orchestration.scss"],"names":[],"mappings":"AAiBA;EACE,eAHU;ACbZ;;AAAA;EACE,uBAAA;EACA,kBAAA;EACA,yDAAA;EACA,iBAAA;EACA,4BAAA;EACA,sBAAA;EACA,kCAAA;EACA,2BAAA;AAGF;;AAEI;EACE,yBDLY;ECMZ,YAAA;EACA,0BAAA;EACA,gBAAA;AACN;AAEI;EACE,aAAA;EACA,eAAA;EACA,cAAA;AAAN;AAIE;EACE,aAAA;EACA,eAAA;AAFJ","sourcesContent":["$dark: rgb(0, 0, 0);\n$color-dark: rgb(0, 0, 0);\n$primary: #87163f;\n$primar-color:#87163f;\n$shadow: 0 6px 6px #000;\n$neutral: rgba(0, 0, 0, 0.05);\n$border: #d7d5e5;\n$radius: 8px;\n$white: #fff;\n$accent: #a535f7;\n$text-dark: #000000;\n$secondary-color: #d7d5e5;\n$secondary-light-color: #afadcc;\n$secondary-dark-color: #323247;\n$secondary-active-color: #211f3d;\n$body-text: 1rem;\n\n.body_text {\n  font-size: $body-text;\n}","@import \"../../variable.scss\";\n\n.orchestration-bg {\n  /* padding-top: 35px; */\n  padding-top: 100px;\n  background-image: url(\"../../assets/2BOS-underconstructionbg.png\");\n  min-height: 100vh;\n  background-repeat: no-repeat;\n  background-size: cover;\n  /* background-attachment: fixed; */\n  background-position: center;\n}\n\n.my_table {\n  thead {\n    th {\n      background-color: $secondary-color;\n      height: 44px;\n      text-transform: capitalize;\n      font-weight: 500;\n    }\n\n    td {\n      padding: 1rem;\n      font-size: 14px;\n      color: #211f3d;\n    }\n  }\n\n  .table > :not(caption) > * > * {\n    padding: 1rem;\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
