import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../Home/OtherUserHome.css";
import Homebg from "../../assets/2BOS-homebg.jpg";
import { Helmet } from "react-helmet";
import {
  getCurrentUrl,
  hasAndIsDefinedInSessionStorage,
  parseTokenFromUrl,
  setInSessionStorage,
} from "../../utils/utils";
import _ from "lodash";

function OtherUserHome() {
  const navigate = useNavigate();
  useEffect(() => {
    const url = parseTokenFromUrl(getCurrentUrl());
    if (!_.isEmpty(url)) {
      setInSessionStorage("accessToken", url["accessToken"]);
      setInSessionStorage("idToken", url["idToken"]);
      setInSessionStorage("tokenType", url["tokenType"]);
      setInSessionStorage("expiresIn", url["expiresIn"]);
    }
    if (
      !hasAndIsDefinedInSessionStorage("accessToken") &&
      !hasAndIsDefinedInSessionStorage("idToken")
    ) {
      navigate("/");
    }
  }, []);

  return (
    // <div>OtherUserHome</div>
    <div className="col-md-12">
      {/* <div className="otherhome-bg"></div> */}
      <div className="col-md-12 pb-4">
        <div className="otherhome-heading">
          <div className="otherhome-heading-bg">
            <img
              src={Homebg}
              alt=""
              style={{ width: "100%", height: "500px", borderRadius: "0px" }}
            />
          </div>
          <div className="otherhome-heading-area">
            <div className="otherheading-area-inside">
              <h1>Welcome to 2BOS</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtherUserHome;
